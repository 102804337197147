import { ContentSwitcher } from "carbon-components-react"
import CustomComboBox from "../../../components/CustomComboBox"
import { ACCOUNT_TYPE_ACCOUNTANT, ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_SUPERVISOR } from "../../../constants/Constants"
import { getAccountRole } from "../../../session/SessionManager"
import Util from "../../../util/Util"
import { AccountLedgerSelector, SubsidiaryLedgerSelector } from "../../components/basic-filter/account-ledger-selector"
import { AmountTagSelector } from "../../components/basic-filter/amount-tag-selector"
import { DateRange } from "../../components/basic-filter/date-range"
import { Divider } from "../../components/basic-filter/divider"
import { ObjectTypeSelector } from "../../components/basic-filter/object-type-selector"
import { TextBox } from "../../components/basic-filter/text-box"
import JournalEntriesItemPage from "../../../pages/journal-entries/JournalEntriesItemPage"

export const LedgerReport = {
    filter: Filter,
    payload: Payload,

    // freezeCol: 1,
    itemToOpenLink: item => `/journal-entry/${item.jrId}`,

    xlSideView: true,
    skipTheFormulaBar: true,
    // hasPayloadBar: true,

    sideViewCustomTab: {
        name: "Journal Entry",
        component: JournalEntryPreview
    },

    isAvailable: () => {
        const role = getAccountRole();
        return role == ACCOUNT_TYPE_ADMINISTRATION || role == ACCOUNT_TYPE_SUPERVISOR || role == ACCOUNT_TYPE_ACCOUNTANT;
    }
}

function JournalEntryPreview({ item }) {
    return (
        <div>
            <JournalEntriesItemPage mini pagePathParamsOverride={{ journalEntryId: item.jrId }} />
        </div>
    )
}

function Filter({ endpoint, state }) {
    return (<>
        <DateRange state={state} property="date" />
        <Divider />

        <AccountLedgerSelector parentStyle={{ flex: 1 }} fieldStyle={{ width: '100%' }} accountTree={endpoint.accountTree} state={state} label="Account" property="ledgerId" />
        <SubsidiaryLedgerSelector parentStyle={{ flex: 1 }} fieldStyle={{ width: 'calc(100% + 0.40rem)' }} accountTree={endpoint.subsidiaryAccounts} state={state} label="Subsidiary" property="subsidiaryId" typeProperty="subsidiaryType" />


        <div style={{ height: 30, marginTop: '0rem', flexBasis: '100%' }}>
            <AmountTagSelector options={endpoint.tags} state={state} property="tags" />
        </div>


        {/* <DateRange state={state} property="created" />
        <Divider />
        <TextBox state={state} label="Customer" property="customer" /> */}
    </>)
}

function Payload({ payload }) {
    return (<>
        <p style={{ fontSize: 12, opacity: 0.65, fontWeight: 'bold', marginRight: '0.25rem' }}>Balance:</p>

        {payload.amount === null ? (
            <p style={{ fontSize: 12, opacity: 0.65 }}>No account selected</p>
        ) : (
            <p>AED {Util.formatMoney(payload.amount)}</p>
        )}
    </>)
}