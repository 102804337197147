import { useEffect, useMemo, useState } from 'react'
import './report-view.scss'
import { CaretRight16 } from '@carbon/icons-react'
import Api from '../../../session/Api'
import UIUtil from '../../../util/UIUtil'
import { InlineLoading } from 'carbon-components-react'
import { getReportLink } from '../../base/report-page'
import { useHistory } from 'react-router-dom'


const Layout = ({ showFooter, data, children }) => (
    <div style={{ width: '85%', marginInline: 'auto', height: '100%', background: '#F7F7F8', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
        <div style={{ height: '1rem' }} />
        <p style={{ fontSize: 14 }}>{data.companyName}</p>
        <p style={{ fontSize: 22, fontWeight: 'bold' }}>{data.reportName}</p>
        <p style={{ fontSize: 16 }}>{data.date}</p>
        <div style={{ padding: '1rem', paddingBottom: showFooter ? 0 : '1rem', width: '100%', minHeight: 0, flex: 1 }}>
            <div style={{ width: '100%', height: '100%', background: 'white', borderRadius: 15, border: '1px solid #00000005', overflow: 'hidden', boxShadow: '0px 20px 25px -5px rgba(0,0,0,0.1) , 0px 10px 10px -5px rgba(0,0,0,0.04) ' }}>
                <div style={{ width: '100%', height: '100%', overflow: 'auto' }}>
                    <div style={{ position: 'relative', minWidth: '100%', minHeight: '100%', display: 'inline-block' }}>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    </div>
)

const Cell = ({ expanded, onClick, children, isRowHeader, skipBorderRightHighlight, isGroupHeader, isColHeader, isTotal, col, valueKey, reportOpt, modifier = 0 }) => {
    const rightAligned = (reportOpt?.rightAligned ?? []).includes(valueKey);
    const frozenWidth = reportOpt?.frozenWidth || 200;

    return (
        <div onClick={onClick} className="report-view-cell" style={{
            minWidth: isRowHeader ? frozenWidth : 75, maxWidth: isRowHeader ? frozenWidth : undefined,
            flex: isRowHeader ? undefined : 1,
            cursor: onClick ? 'pointer' : undefined,

            position: isRowHeader ? 'sticky' : undefined,
            left: isRowHeader ? 0 : undefined,
            background: isRowHeader ? 'white' : undefined,

            ...(rightAligned ? { justifyContent: 'flex-end' } : undefined),

            // borderRight: (isRowHeader && !skipBorderRightHighlight) ? '1px solid #00000040' : undefined
        }}>
            {isGroupHeader ? (
                <div style={{ display: 'flex', alignItems: 'center', paddingRight: '0.5rem', paddingLeft: `calc(${0.5 * (modifier + 1)}rem - 16px)` }}>
                    <CaretRight16 style={{ transition: '250ms', transform: expanded ? 'rotate(90deg)' : 'rotate(0deg)' }} />
                    <p style={{ fontSize: 16, wordBreak: 'break-all', fontWeight: 'bold', }}>
                        {children}
                    </p>
                </div>
            ) : isColHeader ? (
                <p style={{ fontSize: 16, wordBreak: 'break-all', fontWeight: 'bold', paddingRight: '0.5rem', paddingLeft: `${0.5 * (modifier + 1)}rem` }}>{children}</p>
            ) : isTotal ? (
                <p style={{ fontSize: 16, wordBreak: 'break-all', opacity: 0.65, fontStyle: 'italic', paddingRight: '0.5rem', paddingLeft: `${0.5 * (modifier + 1)}rem` }}>{children}</p>
            ) : (
                <p style={{ fontSize: 16, wordBreak: 'break-all', paddingRight: '0.5rem', paddingLeft: `${0.5 * (modifier + 1)}rem`, textAlign: rightAligned ? 'right' : undefined }}>
                    {children}
                </p>
            )}
        </div>
    )
}

const Row = ({ expanded, children }) => {
    return (
        <div className="report-view-row" style={{ minHeight: 25, borderBottom: '1px solid #00000020', boxShadow: expanded ? '0px 1px 3px 0px rgba(0,0,0,0.1) , 0px 1px 2px 0px rgba(0,0,0,0.06) ' : undefined }}>
            {children}
        </div>
    )
}

const Item = ({ itemId, cols, values, name, modifier, onClick, reportOpt }) => {
    return (<>
        <Row>
            <Cell isRowHeader modifier={modifier} reportOpt={reportOpt}>{name}</Cell>
            {/* {cols.map(col => <Cell key={col.key}>{values[col.key]}</Cell>)} */}
            {onClick && !values.skip_click_btn ? (
                cols.map(col => <Cell reportOpt={reportOpt} key={col.key} valueKey={col.key} onClick={() => onClick?.({
                    locationId: parseInt(col.key),
                    productId: itemId,
                    locationName: cols.name,
                    productName: name,
                    __the_actual_row: values
                })}>
                    <span style={{ color: '#0f62fe', textDecoration: 'underline', cursor: 'pointer' }}>
                        {values[col.key]}</span>
                </Cell>)
            ) : (
                cols.map(col => <Cell reportOpt={reportOpt} key={col.key} valueKey={col.key}>{values[col.key]}</Cell>)
            )}
        </Row>
    </>)
}

const ItemGroup = ({ cols, values, name, modifier, children, reportOpt }) => {
    const [expanded, setExpanded] = useState(true);
    return (<>
        <Row
        //expanded={expanded}
        >
            <Cell modifier={modifier} isRowHeader isGroupHeader expanded={expanded} onClick={() => setExpanded(p => !p)} reportOpt={reportOpt}>{name}</Cell>
            {expanded ? <>
                {cols.map(col => <Cell key={col.key} reportOpt={reportOpt} />)}
            </> : <>
                {cols.map(col => <Cell key={col.key} col={col} reportOpt={reportOpt} valueKey={col.key}>{values[col.key]}</Cell>)}
            </>}
        </Row>

        {//expanded && <div style={{ animation: 'report-view-item-group-animate 250ms' }}>
            expanded && <div>
                {children}

                <Row>
                    <Cell isRowHeader modifier={modifier} isTotal reportOpt={reportOpt}>Total {name}</Cell>
                    {cols.map(col => <Cell key={col.key} col={col} reportOpt={reportOpt} valueKey={col.key}>{values[col.key]}</Cell>)}
                </Row>
            </div>}
    </>)
}


const Rows = ({ reportOpt, rows, data, onClick, modifier = 0 }) => (
    rows.map((row) => (
        row.rows?.length ? (
            <ItemGroup key={row.id} name={row.name} cols={data.cols} values={row.values} modifier={modifier} reportOpt={reportOpt}>
                <Rows onClick={onClick} rows={row.rows} modifier={modifier + 2} data={data} reportOpt={reportOpt} />
            </ItemGroup>
        ) : (
            <Item key={row.id} onClick={onClick} itemId={row.id} name={row.name} cols={data.cols} values={row.values} modifier={modifier} reportOpt={reportOpt} />
        )
    ))
)

//const DEF = { cols: [], rows: [] }

export function ReportView({ showFooter, reportName, reportRequest, reportOpt }) {
    const [data, setData] = useState(undefined);

    const history = useHistory();

    useEffect(() => {
        let cancelled = false;
        let timeout = setTimeout(() => {
            if (cancelled) {
                return;
            }

            // console.log('calling@@@@@')
            Api.getReportResult(reportName, reportRequest, response => {
                if (cancelled) {
                    return;
                }

                if (response.status === true) {
                    setData(response.payload)
                } else {
                    UIUtil.showError(response.message);
                }
            })
        }, 50)
        return () => {
            clearTimeout(timeout);;
            cancelled = true;
            setData(undefined)
        };
    }, [reportName, reportRequest])

    const onClick = ({
        locationId,
        productId,
        locationName,
        productName,
        __the_actual_row
    }) => {
        if (reportName !== "StockQtyByLocReport") {
            reportOpt?.onClick(history, __the_actual_row)
            return;
        }

        const date = reportRequest?.filterRequest?.filters?.find(f => f.property === "date")?.value

        window.open(getReportLink("ProductStockReport", {
            "filters": [
                {
                    "id": "productStockReportProductId",
                    "skipAutoFilter": true,
                    "operator": "OR",
                    "filters": [
                        {
                            "property": "itemId",
                            "operator": "EQ",
                            "value": productId
                        }
                    ]
                },
                {
                    "property": "productStockReportLocationId",
                    "operator": "EQ",
                    "skipAutoFilter": true,
                    "id": "productStockReportLocationId",
                    "value": locationId
                },
                ...(date ? ([
                    {
                        "property": "date",
                        "skipAutoFilter": true,
                        "operator": "LT",
                        "id": "date-end",
                        "value": date
                    }
                ]) : [])
            ],
            "productStockReportProductId": {
                "value": productId,
                "label": productName
            },
            "productStockReportLocationId": {
                "id": locationId,
                "name": locationName,
            }
        }), "_blank")
    }
    // const onClick = null;

    const content = useMemo(() => (
        data ? (
            <Layout showFooter={showFooter} data={data}>
                <div style={{ position: 'sticky', top: 0, zIndex: null, boxShadow: '0px 4px 6px -1px rgba(0,0,0,0.1) , 0px 2px 4px -1px rgba(0,0,0,0.06) ' }}>
                    <Row>
                        <Cell isRowHeader skipBorderRightHighlight reportOpt={reportOpt} />
                        {data.cols.map(col => <Cell key={col.key} isColHeader reportOpt={reportOpt}>{col.name}</Cell>)}
                    </Row>
                </div>
                <Rows reportOpt={reportOpt} onClick={reportName === "StockQtyByLocReport" || reportOpt?.onClick ? onClick : undefined} rows={data.rows} data={data} />
            </Layout>
        ) : (
            <div className="centered-progress-bar" style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <InlineLoading style={{ width: 'unset' }} />
            </div>
        )
    ), [data])

    return content;
    // return (
    //     <Layout>
    //         <Row>
    //             <Cell isRowHeader />
    //             {data.cols.map(col => <Cell key={col.key} isColHeader>{col.name}</Cell>)}
    //         </Row>
    //         <Rows rows={data.rows} data={data} />
    //     </Layout>
    // )
}