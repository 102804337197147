import { InlineLoading, TextInput } from "carbon-components-react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import UIUtil from "../../util/UIUtil";
import { useRefSize } from "../../util/useSize";
import Util, { isV2 } from "../../util/Util";
import { Footer } from "../components/report-layout/footer";
import { FormulaValues } from "../components/report-layout/formula-values";
import { QuickFilterBar } from "../components/report-layout/quick-filter-bar";
import { ReportTable } from "../components/table/report-table";
import isEqual from 'lodash.isequal';
import Api from "../../session/Api";
import { ExportToExcelBtn } from "../components/export-btn/export-to-excel-btn";
import { NewReportDesignDialog } from "../dialogs/column-visibility-dialog";
import { PayloadBar } from "../components/report-layout/payload-bar";
import { AdvancedNotesView } from "../../views/advanced-notes/advanced-notes-view";
import { OBJECT_TYPE_ACCOUNT } from "../../constants/ObjectTypes";
import { useForceLockScroll } from "../../hooks/useLockScroll";
import { AdvancedFilterView } from "../components/advanced-filter/advanced-filter-view";
import { advancedFilterNodesToFilters } from "../components/advanced-filter/components/filter-editor";
import { FilterRemove32, AddFilled16, List16 } from '@carbon/icons-react'
import Button from "../../components/Button";
import { AdvancedFilterIndicator } from "../components/report-layout/advanced-filter-indicator";
import { NewBtn } from "../components/buttons/new-btn";
import { ReportView } from "../components/report-view";
import { isMobileScreen } from "../../App";
import { getReport } from "../reports/reports";

const useStateManagement = (state, setState) => ({
    get: key => state[key],
    use: (key, defValue) => {
        return [state[key] ?? defValue, (arg) => {
            if (typeof arg === "function") {
                setState(prevState => ({ ...prevState, [key]: arg(prevState[key] ?? defValue) }))
            } else {
                setState(prevState => ({ ...prevState, [key]: arg }))
            }
        }]
    }
})

function stateToRequest(state) {
    return {
        filter: {},
        filterRequest: {
            filters: [
                ...(state.filters ?? []),
                ...advancedFilterNodesToFilters(state.advancedFilterItems)
            ]
        },
        sortRequest: { sorts: state.sorts ?? [] },
        formulaRequest: { formulas: state.formulas ?? [] }
    }
}

const useReportRequest = (mainState) => {
    const [tableKey, setTableKey] = useState(undefined)
    const [filterRequestKey, setFilterRequestKey] = useState(undefined)
    //const requestRef = useRef(() => stateToRequest(mainState))
    const requestRef = useRef(stateToRequest(mainState))

    useEffect(() => {
        const oldRequest = requestRef.current;
        requestRef.current = stateToRequest(mainState);
        if (!isEqual(oldRequest, requestRef.current)) {
            // console.log("changed TABLE KEY", { oldRequest, req: requestRef.current })
            setTableKey(Util.newTempId())


            const oldFilterRequest = oldRequest.filterRequest;
            const oldFormulaRequest = oldRequest.formulaRequest;
            if (!isEqual(oldFilterRequest, requestRef.current.filterRequest) || !isEqual(oldFormulaRequest, requestRef.current.formulaRequest)) {
                setFilterRequestKey(Util.newTempId())
            }
        }
    }, [mainState])

    return [tableKey, filterRequestKey, requestRef];
}

const useColumns = (report, state, { showGroupNames }) => {
    const initialHidden = useMemo(() => report.columns.filter($ => $.hidden).map($ => $.id), [report.columns])
    const [hiddenColumns, setHiddenColumns] = state.use("hiddenColumns", initialHidden)

    const [columnOrder, setColumnOrder] = state.use("columnOrder", [])
    const [columnWidth, setColumnWidth] = state.use("columnWidth", [])
    const [sorts, setSorts] = state.use("sorts", [])

    const colOrder = (orders, col) => orders.find(column => column.id === col.id)?.order ?? col.order;
    const colWidth = (widths, col) => widths.find(column => column.id === col.id)?.width ?? (Util.isNumberExist(col.width) ? col.width : 150);

    const setColumnVisibility = useCallback((col, visible) => {
        if (col?.type === "TREE_NODE") {
            return;
        }

        return setHiddenColumns(columns => visible ? columns.filter(column => column !== col.id) : [...columns, col.id])
    }, [])
    const onColumnClick = useCallback((col) => {
        if (col?.type === "TREE_NODE") {
            return;
        }

        setSorts(prevSorts => {
            const currColSort = prevSorts.find(sort => sort.property === col.id);
            if (currColSort) {
                if (currColSort.order === "ASC") {
                    return prevSorts.map(sort => sort.property === col.id ? ({
                        ...sort,
                        order: "DESC"
                    }) : sort)
                } else {
                    return prevSorts.filter(sort => sort.property !== col.id)
                }
            } else {
                return [...prevSorts, { property: col.id, order: "ASC" }]
            }
        })
    }, [])
    //const onColumnMoved = useCallback((startIndex, endIndex) => {
    const onColumnMoved = useCallback((fromColumn, toColumn) => {
        if (fromColumn?.type === "TREE_NODE" || toColumn?.type === "TREE_NODE") {
            return;
        }

        setColumnOrder(prevOrders => {
            const old = report.columns.sort((colA, colB) => colOrder(prevOrders, colA) - colOrder(prevOrders, colB)).map((col, i) => ({ id: col.id, order: i }))
            const newCols = [...old];

            const startIndex = newCols.findIndex(col => col.id === fromColumn.id);
            const endIndex = newCols.findIndex(col => col.id === toColumn.id)

            const [toMove] = newCols.splice(startIndex, 1);
            newCols.splice(endIndex, 0, toMove);
            return newCols.map((col, i) => ({ id: col.id, order: i }));
        });
    }, [report]);
    const onColumnResize = useCallback((column, newSize) => {
        setColumnWidth(prevWidths => {
            const old = report.columns.map(col => ({ id: col.id, width: colWidth(prevWidths, col) }))
            return old.map(col => col.id === column.id ? {
                ...col,
                width: newSize
            } : col)
        });
    }, [report]);

    const columns = report.columns
        .map(column => {
            const columnSort = sorts.find(sort => sort.property === column.id)

            const group = showGroupNames ? column.group : undefined;
            const icon = columnSort ? (columnSort.order === "ASC" ? "up" : "down") : undefined;
            return {
                ...column, group, icon,

                width: colWidth(columnWidth, column)
            }
        })
        .sort((colA, colB) => colOrder(columnOrder, colA) - colOrder(columnOrder, colB))
        .filter(col => !hiddenColumns.includes(col.id))

    return {
        columns, onColumnClick, onColumnMoved, onColumnResize, setColumnVisibility
    }
}

function useReportInit(report, requestRef, reload) {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false);

    const [data, setData] = useState({ count: 0, values: [], payload: {} });

    const firstTimeRef = useRef(true);
    useEffect(() => {
        if (!reload) {
            // return;
        }

        if (firstTimeRef.current) {
            firstTimeRef.current = false;
            // return;
        }

        setLoading(true)
        setError(false)
        // setData({ count: 0, values: [], payload: {} })
        // setCount(0)

        let cancelled = false;
        Api.getReportInit(report.name, requestRef.current, response => {
            if (cancelled) {
                return;
            }

            if (response.status === true) {
                setData(response.payload)
            } else {
                setData({ count: 0, values: [], payload: {} })
                setError(true)
            }
            setLoading(false)
        })
        return () => cancelled = true;
    }, [reload])

    return {
        loading, error, data
    }
}

export function ReportContent({
    report: loadedReport,
    endpoint, state: mainState, setState: setMainState, showGroupNames, showFooter,
    showDarkMode, showQuickFilter, showFormulaValues, showPayloadBar, showColumns, setShowColumns,
    showAdvancedFilter, setShowAdvancedFilter, isListMode, embeddedMode, componentMode, selectionMode, viewBased,
    isManageMode
}) {
    const [headerRef, headerSize] = useRefSize()
    const state = useStateManagement(mainState, setMainState)

    const [tableKey, filterRequestKey, reportRequestRef] = useReportRequest(mainState);
    const reportInit = useReportInit(loadedReport, reportRequestRef, filterRequestKey);
    const report = useMemo(() => {
        if (reportInit?.data?.generatedColumns) {
            return { ...loadedReport, columns: reportInit?.data?.generatedColumns }
        } else {
            return { ...loadedReport }
        }
    }, [loadedReport, reportInit])
    const { columns, onColumnClick, onColumnMoved, onColumnResize, setColumnVisibility } = useColumns(report, state, { showGroupNames });

    useForceLockScroll(!componentMode)


    const isTreeStructure = report.treeStructure;
    const clientReportObj = getReport(report.name);
    const isLazyAgg = clientReportObj?.isLazyAgg;
    const skipTheFormulaBar = clientReportObj?.skipTheFormulaBar;


    // console.log({ data: reportInit.data?.preloadedAllData?.[0]?._asOfDate })

    if (reportInit.error) {
        return (
            <div className="centered-progress-bar" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: "100%" }}>
                <p style={{ fontSize: 12, opacity: 0.65 }}>Cannot load invalid report</p>
            </div>
        )
    }

    if (reportInit?.data?.invalidFilter) {
        return (
            <div style={{ height: '100%', position: 'relative' }}>
                <div ref={headerRef} style={{ width: '100%' }}>
                    {showQuickFilter && <QuickFilterBar reportName={report.name} endpoint={endpoint} state={state} showDarkMode={showDarkMode} setShowAdvancedFilter={setShowAdvancedFilter} />}
                </div>
                <div style={{ width: '100%', height: showFooter ? `calc(100% - 25px - ${headerSize.height}px)` : `calc(100% - ${headerSize.height}px)` }}>
                    {reportInit.loading ? (
                        <div className="centered-progress-bar" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: "100%" }}>
                            <InlineLoading style={{ width: 'unset' }} />
                        </div>
                    ) : (
                        <div className="centered-progress-bar" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100%', height: "100%" }}>
                            <FilterRemove32 />
                            <p style={{ fontSize: 14, opacity: 1, marginTop: '1rem' }}>Required filters missing</p>
                            <p style={{ fontSize: 12, opacity: 0.65 }}>{reportInit?.data?.invalidFilterMessage}</p>
                        </div>
                    )}
                </div>
            </div>
        )
    }

    if (isListMode) {
        return (
            <div style={{ width: '100%', height: componentMode ? '100%' : 'calc(100% + 3rem + 37px)', paddingInline: componentMode ? 0 : isManageMode ? '1rem' : '3rem', paddingBottom: componentMode ? 0 : isManageMode ? '1rem' : '3rem', marginTop: componentMode ? undefined : 'calc(-3rem + -37px)' }}>
                <div style={{ position: 'relative', width: '100%', height: '100%', borderRadius: 15, border: selectionMode ? '1px solid #00000015' : 'none', overflow: 'hidden', boxShadow: '0px 20px 25px -5px rgba(0,0,0,0.1) , 0px 10px 10px -5px rgba(0,0,0,0.04) ' }}>
                    <div ref={headerRef} style={{ width: '100%', height: 'calc(3rem - 1px)', background: '#F7F7F8', borderBottom: '1px solid #00000020', display: 'flex', alignItems: 'center', paddingRight: '0.4rem' }}>
                        {!embeddedMode && <>
                            {!isMobileScreen && <QuickFilterBar isListMode={isListMode} reportName={report.name} endpoint={endpoint} state={state} showDarkMode={showDarkMode} setShowAdvancedFilter={setShowAdvancedFilter} />}
                            {isMobileScreen && <div style={{ flex: 1 }} />}
                            <NewBtn state={state} reportName={report.name} isListMode={isListMode} endpoint={endpoint} />
                        </>}

                        {selectionMode && <>
                            {/* <p style={{ paddingLeft: '1rem', opacity: 0.65, fontSize: 12 }}>Select items</p> */}
                            <QuickFilterBar isListMode={isListMode} reportName={report.name} endpoint={endpoint} state={state} showDarkMode={showDarkMode} setShowAdvancedFilter={setShowAdvancedFilter} />
                            {/* <div style={{ flex: 1 }} /> */}
                            {selectionMode.isAnySelected() &&
                                <Button onClick={() => selectionMode.clearSelection()} kind="secondary" renderIcon={List16} size="sm" style={{ borderRadius: 10, }}>Clear</Button>}
                        </>}
                    </div>

                    <div style={{ width: '100%', height: showFooter ? `calc(100% - 25px - ${headerSize.height}px)` : `calc(100% - ${headerSize.height}px)` }}>
                        {reportInit.loading ? (
                            <div className="centered-progress-bar" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: "100%" }}>
                                <InlineLoading style={{ width: 'unset' }} />
                            </div>
                        ) : (
                            <ReportTable
                                //reloadRequest={tableKey}
                                key={tableKey}

                                reportName={report.name}
                                allColumns={report.columns}
                                slowMode={report.slowMode}


                                showDarkMode={showDarkMode}
                                columns={columns}
                                onColumnClick={onColumnClick}
                                onColumnMoved={onColumnMoved}
                                onColumnResize={onColumnResize}
                                setColumnVisibility={setColumnVisibility}


                                reportRequest={reportRequestRef.current}
                                rowCount={reportInit.data.count}


                                isListMode={isListMode}
                                isManageMode={isManageMode}
                                selectionMode={selectionMode}

                                endpoint={endpoint}
                                state={state}

                                aggs={reportInit.data.aggs}
                                preloadedAllData={reportInit.data.preloadedAllData}
                                skipTheFormulaBar={skipTheFormulaBar}
                            />
                        )}
                    </div>
                    {showFooter && <Footer isLazyAgg={isLazyAgg} isTreeStructure={isTreeStructure} count={reportInit.data.count} showDarkMode={showDarkMode} isListMode={isListMode}>
                        {!selectionMode && <ExportToExcelBtn reportName={report.name} columns={columns} request={reportRequestRef.current} />}
                    </Footer>}
                </div>

                <NewReportDesignDialog open={showColumns} onClose={() => setShowColumns(false)}
                    allColumns={report.columns} setColumnVisibility={setColumnVisibility}
                    actualColumns={columns} />

                {showAdvancedFilter &&
                    <div onClick={() => setShowAdvancedFilter(false)} style={{ width: '100%', height: '100%', paddingLeft: '25%', paddingTop: '3rem', position: 'absolute', left: 0, top: 0, background: 'rgba(0, 0, 0, 0.65)', boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) ', animation: '250ms report-engine-advanced-filter-background-fade-in', }}>
                        <div onClick={e => e.stopPropagation()} style={{ height: '100%', width: '100%', background: 'white', animation: '250ms report-engine-advanced-filter-panel-fade-in', }}>
                            <AdvancedFilterView columns={report.columns} state={state} onBackBtn={() => setShowAdvancedFilter(false)} title="Advanced Filter" />
                        </div>
                    </div>}
            </div>
        )
    }


    return (
        <div style={{ height: '100%', position: 'relative' }}>
            <div ref={headerRef} style={{ width: '100%' }}>
                {showQuickFilter && <QuickFilterBar reportName={report.name} endpoint={endpoint} state={state} showDarkMode={showDarkMode} setShowAdvancedFilter={setShowAdvancedFilter} />}
                {!viewBased && !isTreeStructure && showFormulaValues && !skipTheFormulaBar && <FormulaValues showDarkMode={showDarkMode} state={state} columns={report.columns} values={reportInit.data.values} />}
                {showPayloadBar && <PayloadBar reportName={report.name} showDarkMode={showDarkMode} payload={reportInit.data.payload} />}
            </div>
            <div style={{ width: '100%', height: showFooter ? `calc(100% - 25px - ${headerSize.height}px)` : `calc(100% - ${headerSize.height}px)` }}>
                {reportInit.loading ? (
                    <div className="centered-progress-bar" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: "100%" }}>
                        <InlineLoading style={{ width: 'unset' }} />
                    </div>
                ) : (
                    viewBased ? (
                        <ReportView
                            reportName={report.name}
                            reportRequest={reportRequestRef.current}
                            showFooter={showFooter}
                            reportOpt={clientReportObj?.cellOpt}
                        />
                    ) : (
                        <ReportTable
                            //reloadRequest={tableKey}
                            key={tableKey}

                            reportName={report.name}
                            allColumns={report.columns}
                            slowMode={report.slowMode}


                            showDarkMode={showDarkMode}
                            columns={columns}
                            onColumnClick={onColumnClick}
                            onColumnMoved={onColumnMoved}
                            onColumnResize={onColumnResize}
                            setColumnVisibility={setColumnVisibility}


                            reportRequest={reportRequestRef.current}
                            rowCount={reportInit.data.count}
                            isTreeStructure={isTreeStructure}

                            aggs={reportInit.data.aggs}
                            preloadedAllData={reportInit.data.preloadedAllData}
                            skipTheFormulaBar={skipTheFormulaBar}
                        />
                    )
                )}

            </div>
            {showFooter && <Footer isLazyAgg={isLazyAgg} isTreeStructure={isTreeStructure} viewBased={viewBased} count={reportInit.data.count} showDarkMode={showDarkMode}>
                <ExportToExcelBtn reportName={report.name} columns={columns} request={reportRequestRef.current} />
            </Footer>}

            <NewReportDesignDialog open={showColumns} onClose={() => setShowColumns(false)}
                allColumns={report.columns} setColumnVisibility={setColumnVisibility}
                actualColumns={columns} />

            {showAdvancedFilter &&
                <div onClick={() => setShowAdvancedFilter(false)} style={{ width: '100%', height: '100%', paddingLeft: '25%', position: 'absolute', left: 0, top: 0, background: 'rgba(0, 0, 0, 0.65)', boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) ', animation: '250ms report-engine-advanced-filter-background-fade-in', }}>
                    <div onClick={e => e.stopPropagation()} style={{ height: '100%', width: '100%', background: 'white', animation: '250ms report-engine-advanced-filter-panel-fade-in', }}>
                        <AdvancedFilterView columns={report.columns} state={state} onBackBtn={() => setShowAdvancedFilter(false)} title="Advanced Filter" />
                    </div>
                </div>}
        </div>
    )
}
