import { ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_SUPERVISOR } from "../../../constants/Constants"
import { getAccountRole } from "../../../session/SessionManager"
import { isV2 } from "../../../util/Util";
import { CheckboxFilter } from "../../components/basic-filter/check-box-filter";
import { DateRange } from "../../components/basic-filter/date-range";
import { openAccountLedger } from "../balance-sheet0";
import { AmountTagSelector } from "../../components/basic-filter/amount-tag-selector"
import { openAccountLedger2 } from "../balance-sheet";

export const ProfitLoss = {
    filter: Filter,
    // isTreeSructure: true,
    // loadTreeOnly: true,
    viewBased: true,
    cellOpt: {
        frozenWidth: 400,
        rightAligned: ['formattedBalance'],
        onClick: (history, data) => openAccountLedger2(data)
    },

    customPath: !isV2() ? "/profit-and-loss-statement" : undefined,

    onAction: (action, data, history) => {
        switch (action) {
            case "balance":
                // openAccountLedger("ProfitLoss", history, data.accountId, data.recordGroup?.replace(" Total", ""), data._startDate, data._endDate)
                openAccountLedger("ProfitLoss", history, data.acc_id, data.route?.replace(" Total", ""), data._startDate, data._endDate, true)
                return;
        }
    },

    isAvailable: () => {
        const role = getAccountRole();
        return role == ACCOUNT_TYPE_ADMINISTRATION || role == ACCOUNT_TYPE_SUPERVISOR;
    }
}

const SHOW_ZERO_BALANCE_FILTER = {
    skipAutoFilter: true,
    value: true
}

function Filter({ endpoint, state }) {
    return (<>
        <DateRange state={state} property="date" />
        <CheckboxFilter state={state} filterId="showZeroBalance" label="Show 0 balance" filter={SHOW_ZERO_BALANCE_FILTER} />

        <div style={{ height: 30, marginTop: '0rem', flexBasis: '100%' }}>
            <AmountTagSelector options={endpoint.tags} state={state} property="tags" />
        </div>
    </>)
}
