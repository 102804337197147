import { Button, ButtonSet, ModalHeader, TextInput, InlineLoading, Link, ClickableTile, Tag } from 'carbon-components-react';
import React, { useEffect, useMemo, useRef, useState } from 'react'
import usePagination from '../../hooks/usePagination';
import Util from '../../util/Util';

import { ErrorFilled16, ArrowRight16, CheckmarkFilled16, AppConnectivity16 } from '@carbon/icons-react'
import Api from '../../session/Api';
import ImageView from '../../components/ImageView';
import { OBJECT_TYPE_PRODUCT, OBJECT_TYPE_PRODUCT_BUNDLE } from '../../constants/ObjectTypes';
import axios from 'axios';
import UIUtil from '../../util/UIUtil';
import { ProductGroupSetAttribute } from './ProductGroupSetAttribute';

const ROW_COUNT = 10;

const LoadingBar = () => (
    <div style={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
        <InlineLoading style={{ width: 'unset' }} />
        <p style={{ fontSize: 12, opacity: 0.65 }}>Loading</p>
    </div>
)

const ErrorBar = ({ onTryAgainBtn }) => (
    <div style={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', }}>
        <ErrorFilled16 style={{ color: 'darkred', marginRight: '0.5rem' }} />
        <p style={{ fontSize: 12, opacity: 0.65, color: 'darkred' }}>Failed to load</p>

        <Link onClick={onTryAgainBtn} style={{ marginLeft: '0.5rem', cursor: 'pointer' }}>Try again</Link>
    </div>
)

const LoadedAllBar = ({ rowCount }) => (
    <div style={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
        {/* <InlineLoading style={{width: 'unset'}} /> */}
        <p style={{ fontSize: 12, opacity: 0.65 }}>Loaded all {rowCount} row{rowCount != 1 && 's'}</p>
    </div>
)

const Item = ({ item, onClick, onSpecificIdClick, bulkSelect, selected }) => (
    <div style={{ marginBottom: '0.5rem', display: 'flex' }}>
        <ClickableTile light handleClick={onClick} style={{ flex: 1, border: selected ? '1px solid #161616' : undefined }}>
            <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                <ImageView src={Api.getThumbnail(item.type || OBJECT_TYPE_PRODUCT, item.id)} style={{ height: 64, width: 64, marginRight: 10, objectFit: 'contain', objectPosition: 'center', alignSelf: 'flex-start', backgroundColor: 'white' }} />
                <div style={{ flex: 1 }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
                        <h4 style={{ fontSize: 14 }}>{item.name}</h4>
                        {item.type === OBJECT_TYPE_PRODUCT && <Tag type="blue" size="sm">Product</Tag>}
                        {item.type === OBJECT_TYPE_PRODUCT_BUNDLE && <Tag type="green" size="sm">Bundle</Tag>}
                    </div>
                    <p style={{ fontSize: 12, opacity: 0.65 }}>{item.sku}</p>

                    <div style={{
                        display: 'flex', justifyContent: 'space-between',
                        marginTop: '0.5rem', marginBottom: '0.5rem', width: '100%', padding: '0.5rem 1rem', background: 'white', borderRadius: 5, boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.1)'
                    }}>
                        <div>
                            <p style={{ fontSize: 12, opacity: 0.65 }}>Brand</p>
                            <h5 style={{ margin: 0 }}>{item.brandName == "No brand specified" ? "-" : item.brandName}</h5>
                        </div>

                        {item.type !== OBJECT_TYPE_PRODUCT_BUNDLE && <div>
                            <p style={{ fontSize: 12, opacity: 0.65 }}>Cost</p>
                            <h5 style={{ margin: 0 }}>AED {item.cost}</h5>
                        </div>}

                        <div>
                            <p style={{ fontSize: 12, opacity: 0.65 }}>Price</p>
                            <h5 style={{ margin: 0 }}>AED {item.price}</h5>
                        </div>

                        {item.type !== OBJECT_TYPE_PRODUCT_BUNDLE && <div>
                            <p style={{ fontSize: 12, opacity: 0.65 }}>Stock Amount</p>
                            <h5 style={{ margin: 0 }}>{item.stockCount} {item.uom}</h5>
                        </div>}
                    </div>

                    {Util.isStringExists(item.desc) &&
                        <p style={{ fontSize: 14, opacity: 0.65 }}>{Util.limitString(item.desc)}</p>}

                    {Util.isNumberExist(item.defaultProductGroupId) &&
                        <Button onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();

                            UIUtil.showOverlay2(onClose => <ProductGroupSetAttribute defaultProductId={item.id} open onClose={onClose} groupId={item.defaultProductGroupId} onSelect={onSpecificIdClick} />)
                        }} renderIcon={AppConnectivity16} size="sm" style={{ borderRadius: 15, display: 'flex', justifyContent: 'center', alignItems: 'center', }}>Select Attribute</Button>}
                </div>

                {/* <p style={{fontSize: 12}}>{customerName}</p>
                <div style={{width: 4, height: 4, marginLeft: 5, marginRight: 5, borderRadius: '50%', background: 'black', opacity: 1}} />
                <p style={{fontSize: 12}}>{totalItems}</p>
                <div style={{width: 4, height: 4, marginLeft: 5, marginRight: 5, borderRadius: '50%', background: 'black', opacity: 1}} />
                <p style={{fontSize: 12, color: 'green'}}>{totalAmount}</p> */}

                <div style={{ width: 10 }} />
                {bulkSelect ? (
                    selected && <CheckmarkFilled16 />
                ) : (
                    <ArrowRight16 />
                )}
            </div>
        </ClickableTile>
    </div>
)

const MiniItem = ({ item, onClick, bulkSelect, selected }) => (
    <div style={{ marginBottom: '0.15rem', display: 'flex' }}>
        <ClickableTile light handleClick={onClick} style={{ flex: 1, border: selected ? '1px solid #161616' : undefined, padding: '0.5rem', minHeight: 'unset' }}>
            <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                <ImageView imageViewStyle={{ borderRadius: '50%' }} src={Api.getThumbnail(item.type || OBJECT_TYPE_PRODUCT, item.id)} style={{ borderRadius: '50%', height: 16, width: 16, marginRight: 10, objectFit: 'contain', objectPosition: 'center', backgroundColor: 'white' }} />
                <div style={{ flex: 1 }}>
                    <h4 style={{ fontSize: 14 }}>{item.name}</h4>
                </div>

                <div style={{ width: 10 }} />
                {bulkSelect ? (
                    selected && <CheckmarkFilled16 />
                ) : (
                    <ArrowRight16 />
                )}
            </div>
        </ClickableTile>
    </div>
)

export default ({ onClose, onProductClick, onBulkSelect, showFakeTextFieldAtFirst, bulkSelect, loadProductsAndRest }) => {
    const [inSearchMode, setInSearchMode] = useState(false);
    const [searchValue, setSearchValue] = useState("");

    const [items, setItems] = useState([]);
    const [selectedItems, setSelectedItems] = useState([])

    const visibleSelections = useMemo(() => selectedItems.filter(selectedItem => items.filter(item => item.id == selectedItem.id).length == 0), [items, selectedItems])

    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [inError, setInError] = useState(false);
    const [hasMore, setHasMore] = useState(true);

    const [showTextField, setShowTextField] = useState(!showFakeTextFieldAtFirst);
    const [showFakeTextField, setShowFakeTextField] = useState(showFakeTextFieldAtFirst);

    const searchInputRef = useRef();
    const listRef = useRef();
    const searchTimerRef = useRef();
    const searchReqTokenRef = useRef();

    useEffect(() => {
        if (showTextField && searchInputRef.current) {
            searchInputRef.current.focus();
        }
    }, [showTextField])

    useEffect(() => {
        if (searchInputRef.current) {
            searchInputRef.current.focus();
        }
    }, [])

    const clearPageState = () => {
        // setItems([])
        setPage(0);
        setLoading(false);
        setInError(false)
        setHasMore(true);
    }

    const shouldLoadPage = () => {
        if (loading || inError || !hasMore) {
            return false;
        }

        const remainingScroll = listRef.current.scrollHeight - listRef.current.scrollTop - listRef.current.offsetHeight;
        const scrollThreshold = (listRef.current.offsetHeight / items.length) * (ROW_COUNT / 2);

        return remainingScroll <= scrollThreshold;
    };

    const loadPage = () => {
        setInError(false)
        setLoading(true)
        if (searchReqTokenRef.current) {
            searchReqTokenRef.current.cancel();
        }
        searchReqTokenRef.current = axios.CancelToken.source();
        const listener = response => {
            if (response.status === false && !!response.__exception && axios.isCancel(response.__exception)) {
                return;
            }

            if (response.status === true) {
                setPage(page + 1);

                const { loadedItems, loadedHasMore } = {
                    loadedItems: response.payload.items,
                    loadedHasMore: response.payload.hasMore
                }

                if (page == 0) {
                    setItems(loadedItems)
                } else {
                    setItems(items => [...items, ...loadedItems])
                }
                setHasMore(loadedHasMore)
            } else {
                setInError(true)
            }
            setLoading(false)
        }

        if (loadProductsAndRest) {
            Api.searchProductsAndRest(searchValue, page, searchReqTokenRef.current, listener);
        } else {
            Api.searchProducts(searchValue, page, searchReqTokenRef.current, listener);
        }
    }


    // useEffect(() => {
    //     if (page == 0 && inSearchMode) {
    //         loadPage()
    //     }
    // }, [page, inSearchMode]);
    useEffect(() => {
        if (!listRef.current) {
            return;
        }

        const list = listRef.current;
        const onScroll = () => shouldLoadPage() && loadPage();
        list.addEventListener('scroll', onScroll, { passive: true })
        return () => list.removeEventListener('scroll', onScroll);
    }, [page, loading, hasMore]);


    useEffect(() => {
        let cancelled = false;

        if (Util.isStringExists(searchValue)) {
            setInSearchMode(true)
            if (searchTimerRef.current) {
                clearTimeout(searchTimerRef.current)
            }
            searchTimerRef.current = setTimeout(() => {
                clearPageState();
                setTimeout(() => {
                    if (cancelled) {
                        return;
                    }

                    loadPage();
                }, 100);
            }, 75)
        } else {
            setInSearchMode(false)
            setItems([])
        }

        return () => {
            cancelled = true;
            if (searchTimerRef.current) {
                clearTimeout(searchTimerRef.current)
            }
        }
    }, [searchValue])


    const isItemSelected = item => {
        for (const selectedItem of selectedItems) {
            if (selectedItem.id == item.id) {
                return true;
            }
        }
        return false;
    }
    const onItemClicked = item => {
        if (bulkSelect) {
            if (isItemSelected(item)) {
                setSelectedItems(selectedItems => selectedItems.filter(selectedItem => selectedItem.id != item.id))
            } else {
                setSelectedItems(selectedItems => [...selectedItems, item])
            }
        } else {
            onClose();
            onProductClick(item)
        }
    }

    return (
        <div
            key="product-finder-dialog" style={{ background: 'rgb(244, 244, 244)', width: '50vw', overflow: 'hidden' }}>
            <ModalHeader label="Finder" title={'Product Finder'} />
            <div style={{ height: 15 }} />
            <div style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>


                <TextInput
                    ref={searchInputRef}
                    onClick={e => {

                    }}
                    data-modal-primary-focus
                    labelText="Search" light value={searchValue} onChange={e => setSearchValue(e.target.value)}
                    style={{ display: !showTextField ? 'none' : undefined }}
                    placeholder="Input name or ref number or barcode" />

                <div className="input bx--text-input bx--form-item bx--text__input bx--text-input--light" onClick={() => {
                    setShowTextField(true)
                    setShowFakeTextField(false)
                }} style={{
                    display: !showFakeTextField ? 'none' : 'flex',
                    justifyContent: 'center',
                    color: 'rgba(0,0,0,0.30)',
                    cursor: 'text'
                }}>Input name or ref number or barcode</div>

                {inSearchMode && <div style={{ marginTop: '1rem' }}>
                    <label style={{ marginBottom: '0.5rem' }} className="bx--label">{'Result'}</label>
                    <div ref={listRef} style={{ overflow: 'auto', maxHeight: visibleSelections.length > 0 ? '40vh' : '50vh' }}>
                        {items.map(item => <Item bulkSelect={bulkSelect} key={item.id} item={item} selected={isItemSelected(item)} onClick={() => {
                            onItemClicked(item)
                        }} onSpecificIdClick={id => {
                            onItemClicked({ id })
                        }} />)}
                        <div style={{ height: 45, }}>
                            {loading ? <LoadingBar /> :
                                inError ? <ErrorBar onTryAgainBtn={() => loadPage()} /> :
                                    !hasMore && <LoadedAllBar rowCount={items.length} />}
                        </div>
                    </div>
                </div>}


                {visibleSelections.length > 0 && <>
                    <label style={{ marginBottom: '0.5rem', marginTop: '1rem' }} className="bx--label">{'Selection not visible in search'}</label>
                    <div style={{ overflow: 'auto', maxHeight: '25vh' }}>
                        {visibleSelections.map(item => <MiniItem bulkSelect={bulkSelect} key={item.id} item={item} selected={isItemSelected(item)} onClick={() => {
                            onItemClicked(item)
                        }} />)}
                    </div>
                </>}
            </div>
            <div style={{ height: 15 }} />
            <ButtonSet style={{ justifyContent: 'flex-end' }}>
                <Button kind="secondary" size="lg" onClick={onClose}>
                    Cancel
                </Button>
                {bulkSelect && <Button disabled={selectedItems.length == 0} renderIcon={CheckmarkFilled16} size="lg" onClick={() => {
                    onBulkSelect(selectedItems)
                    onClose();
                }}>
                    Confirm Selection
                </Button>}
            </ButtonSet>
        </div>
    )
}