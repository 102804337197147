import React from 'react'
import { Content, Header, HeaderContainer, Pagination, HeaderGlobalAction, HeaderGlobalBar, HeaderMenuButton, HeaderName, HeaderPanel, Modal, SideNav, SideNavItems, SideNavLink, SideNavMenuItem, SkipToContent, Switcher, SwitcherDivider, SwitcherItem, TableContainer, TableToolbar, TableToolbarAction, TableToolbarContent, TableToolbarMenu, TableToolbarSearch, Tile, HeaderNavigation, DataTableSkeleton, Tag, ButtonSet, TableSelectAll, TableSelectRow, ExpandableSearch } from 'carbon-components-react';
import {
    DataTable,
    Table,
    TableHead,
    TableRow,
    TableHeader,
    TableBody,
    TableCell,
} from 'carbon-components-react';
import {
    Add16, DirectionStraightRight32, Delivery16, Receipt16, DeliveryParcel16, Error16, Save16, ArrowRight16, ArrowLeft16, Calendar16, QueryQueue16,
    CheckboxIndeterminate32,
    Delivery32,
    DataVis_232,
    Store32,
    Cube32,
    AirlinePassengerCare32,
    DirectionStraightRight16,
    Certificate16,
    Calculator16,
    Archive16,
    SettingsCheck16, DeliveryParcel32,
    Identification16,
    Van32,
    ShoppingCartPlus16,
    FlowStream16,
    Movement16,
    InventoryManagement16,
    ShoppingCartMinus16,
    Building32, BuildingInsights_132,
    Industry32, Share16
} from '@carbon/icons-react'
import Api from '../../session/Api';
import UIUtil from '../../util/UIUtil';
import Button from '../../components/Button';
import { Link, useHistory, withRouter } from "react-router-dom";
import DirectionIndicator from '../../components/DirectionIndicator';
import { DESTINATION_TYPE_CUSTOMER, DESTINATION_TYPE_STOCK_TERMINATION, DESTINATION_TYPE_STORE, DESTINATION_TYPE_SUPPLIER, DESTINATION_TYPE_WAREHOUSE, SOURCE_TYPE_CUSTOMER, SOURCE_TYPE_STOCK_CREATION, SOURCE_TYPE_STORE, SOURCE_TYPE_SUPPLIER, SOURCE_TYPE_WAREHOUSE, SOURCE_TYPE_MOBILE_UNIT, STOCK_FLOW_INITIATION_TYPE_POS, TRANSACTION_DIRECTION_TYPE_INWARD, DESTINATION_TYPE_MOBILE_UNIT, STOCK_FLOW_INITIATION_TYPE_SALES_CART, DESTINATION_TYPE_VENUE, SOURCE_TYPE_VENUE } from '../../constants/Constants';
import ProfilePic from '../../components/ProfilePic';
import { OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_CUSTOMER, OBJECT_TYPE_STORE, OBJECT_TYPE_WAREHOUSE, OBJECT_TYPE_SUPPLIER, OBJECT_TYPE_PRODUCT, OBJECT_TYPE_MOBILE_UNIT, OBJECT_TYPE_VENUE } from '../../constants/ObjectTypes';
import Util, { isV2 } from '../../util/Util';
import PaginatedDataTable from '../../components/data-table/PaginatedDataTable';
import { LazySalesReceiptDownload, printSalesReceipt } from '../../pdfs/sales-receipt/SalesReceiptPdf';
import { StockFlowInvoiceBtn } from '../../pdfs/invoice-document/InvoiceDocumentPdf';
import { SidePanel } from '../../templates/draft/components/side-panel';
import StockFlowItemPage from './StockFlowItemPage';
import { LockScroll } from '../product/ProductListPage';
import { hasCapabilitySupport } from '../../app/Capabilities';
import { openPdf, sharePdf } from '../../markup-template-engine';
import { MainApp } from '../../App';
import { ComboBoxField } from '../../components/ComboBoxField';
import { ApiComboBoxField } from '../../components/api-combo-box';

const RadioItem = ({ icon, title, desc }) => (
    <div style={{ display: 'flex', flexDirection: 'column', minWidth: 150 }}>

        <div style={{ display: 'flex', alignItems: 'center' }}>
            {React.createElement(icon, { style: { width: 24, height: 24 } })}
            <p style={{ marginLeft: '0.5rem' }}>{title}</p>
        </div>
        <p style={{ marginTop: '0rem', fontSize: 12, opacity: 1 }}>
            {desc}
        </p>
    </div>
)

const HEADERS = [
    {
        header: "Flow",
        key: "sourceId"
    },
    {
        header: "Type",
        key: "destinationId"
    },
    {
        header: "Stock Quantity",
        key: "totalStockCount"
    },
    {
        header: "Initiation Type",
        key: "initiationType"
    },
    {
        header: "Date",
        key: "initiationDate"
    },
    {
        header: "Amount",
        key: "amount",
    }
]


const SALES_HEADERS = [
    // {
    //     header: "Store",
    //     key: "sourceName"
    // },
    {
        header: "Tax Invoice",
        key: "id"
    },
    {
        header: "Customer",
        key: "destinationName"
    },
    {
        header: "Staff",
        key: "initiatorFullName"
    },
    {
        header: "Quantity",
        key: "totalStockCount"
    },
    {
        header: "Total",
        key: "amount"
    },
    {
        header: "Date",
        key: "initiationDate"
    },
    {
        header: "",
        key: "print"
    }
]

const PURCHASES_HEADERS = [
    {
        header: "Supplier",
        key: "sourceName"
    },
    {
        header: "Store/Warehouse",
        key: "destinationName"
    },
    {
        header: "Staff",
        key: "initiatorFullName"
    },
    {
        header: "Quantity",
        key: "totalStockCount"
    },
    {
        header: "Total",
        key: "amount"
    },
    {
        header: "Date",
        key: "initiationDate"
    }
]

const NEW_PURCHASES_HEADERS = [
    {
        header: "Voucher Number",
        key: "id"
    },
    {
        header: "Supplier",
        key: "sourceName"
    },
    {
        header: "Supplier Invoice No",
        key: "purchaseSupplierInvoiceNo"
    },
    {
        header: "Supplier Invoice Date",
        key: "purchaseSupplierInvoiceDate"
    },
    {
        header: "Posted Date",
        key: "initiationDate"
    },
    {
        header: "Total",
        key: "amount"
    },
]

const NEW_SALES_HEADERS = [
    {
        header: "Tax Invoice",
        key: "id"
    },
    {
        header: "Customer",
        key: "destinationName"
    },
    {
        header: "Date",
        key: "salesDate"
    },
    {
        header: "Total",
        key: "amount"
    },
    {
        header: "",
        key: "print"
    }
]


const FILTER_TYPE_PURCHASES = 0;
const FILTER_TYPE_SALES = 1;
const FILTER_TYPE_PURCHASE_RETURN = 2;
const FILTER_TYPE_SALES_RETURN = 3;
const FILTER_TYPE_STOCK_TRANSFER = 4;
const FILTER_TYPE_FLOW = 5;

const TabItem = ({ icon, title, selected, onClick }) => (
    <Button
        style={{ pointerEvents: selected ? 'none' : undefined }} onClick={onClick}
        hasIconOnly={!selected} iconDescription={!selected ? title : undefined}
        kind={selected ? "secondary" : "ghost"} renderIcon={icon}>{selected && title}</Button>
)


class StockFlowListView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: this.isLoadAll() ? false : true,
            rows: [],
            totalStock: 0,

            idFilter: "",
            voucherNoFilter: "",
            locationFilter: 0,
            typeFilter: props.forceSalesFilter ? FILTER_TYPE_SALES : props.forcePurchaseFilter ? FILTER_TYPE_PURCHASES : -1,

            selectedStockFlowId: undefined
        }
    }

    isFilterForced() {
        return this.props.forceSalesFilter === true || this.props.forcePurchaseFilter === true;
    }

    renderFilterTab() {
        if (this.isFilterForced()) {
            return null;
        }

        return !Util.isStringExists(this.state.idFilter) && !Util.isStringExists(this.state.voucherNoFilter) && (
            <>
                <TabItem key={this.state.typeFilter + '-all-tab'} icon={FlowStream16} title="All" selected={this.state.typeFilter == -1} onClick={() => this.setState({ typeFilter: -1 })} />
                <TabItem key={this.state.typeFilter + '-purchases-tab'} icon={Delivery16} title="Purchases" selected={this.state.typeFilter == FILTER_TYPE_PURCHASES} onClick={() => this.setState({ typeFilter: FILTER_TYPE_PURCHASES })} />
                <TabItem key={this.state.typeFilter + '-sales-tab'} icon={ShoppingCartPlus16} title="Sales" selected={this.state.typeFilter == FILTER_TYPE_SALES} onClick={() => this.setState({ typeFilter: FILTER_TYPE_SALES })} />
                <TabItem key={this.state.typeFilter + '-purchases-return-tab'} icon={InventoryManagement16} title="Purchase Returns" selected={this.state.typeFilter == FILTER_TYPE_PURCHASE_RETURN} onClick={() => this.setState({ typeFilter: FILTER_TYPE_PURCHASE_RETURN })} />
                <TabItem key={this.state.typeFilter + '-sales-returns-tab'} icon={ShoppingCartMinus16} title="Sales Returns" selected={this.state.typeFilter == FILTER_TYPE_SALES_RETURN} onClick={() => this.setState({ typeFilter: FILTER_TYPE_SALES_RETURN })} />
                <TabItem key={this.state.typeFilter + '-stock-transfers-tab'} icon={Movement16} title="Stock Transfers" selected={this.state.typeFilter == FILTER_TYPE_STOCK_TRANSFER} onClick={() => this.setState({ typeFilter: FILTER_TYPE_STOCK_TRANSFER })} />
                <TabItem key={this.state.typeFilter + '-others-tab'} icon={DeliveryParcel16} title="Others" selected={this.state.typeFilter == FILTER_TYPE_FLOW} onClick={() => this.setState({ typeFilter: FILTER_TYPE_FLOW })} />
                <div style={{ flex: 1 }} />
            </>
        )
    }

    getTitle() {
        if (this.props.report) {
            return undefined;
        }

        // if (this.props.locationList && (this.props.locationList.type == OBJECT_TYPE_SUPPLIER || this.props.locationList.type == OBJECT_TYPE_CUSTOMER)) {
        //     return undefined;
        // }

        if (this.props.quotationList) {
            return "Quotations";
        } else if (this.props.locationList && this.props.locationList.type == OBJECT_TYPE_STORE) {
            return <div style={{ display: 'flex', alignItems: 'center' }}> <DeliveryParcel32 style={{ marginRight: '0.5rem' }} /> <h4 className="bx--data-table-header__title">Total Units: {this.state.totalStock}</h4> </div>;
        } else if (this.props.locationList && this.props.locationList.type == OBJECT_TYPE_VENUE) {
            return <div style={{ display: 'flex', alignItems: 'center' }}> <DeliveryParcel32 style={{ marginRight: '0.5rem' }} /> <h4 className="bx--data-table-header__title">Total Units: {this.state.totalStock}</h4> </div>;
        } else if (this.props.locationList && this.props.locationList.type == OBJECT_TYPE_WAREHOUSE) {
            return <div style={{ display: 'flex', alignItems: 'center' }}> <DeliveryParcel32 style={{ marginRight: '0.5rem' }} /> <h4 className="bx--data-table-header__title">Total Units: {this.state.totalStock}</h4> </div>;
        } else if (this.props.locationList && this.props.locationList.type == OBJECT_TYPE_MOBILE_UNIT) {
            return <div style={{ display: 'flex', alignItems: 'center' }}> <DeliveryParcel32 style={{ marginRight: '0.5rem' }} /> <h4 className="bx--data-table-header__title">Total Units: {this.state.totalStock}</h4> </div>;
        } else if (this.props.locationList) {
            return <div style={{ display: 'flex', alignItems: 'center' }}> <DeliveryParcel32 style={{ marginRight: '0.5rem' }} /> <h4 className="bx--data-table-header__title">Total Units: {this.state.totalStock}</h4> </div>;
        } else if (this.props.productList) {
            return <div style={{ display: 'flex', alignItems: 'center' }}> <DeliveryParcel32 style={{ marginRight: '0.5rem' }} /> <h4 className="bx--data-table-header__title">Total Units: {this.state.totalStock}</h4> </div>;
        } else {
            return "Stock Flow";
        }
    }

    getSubtitle() {
        if (this.props.report) {
            return undefined;
        }

        if (this.props.locationList && (this.props.locationList.type == OBJECT_TYPE_SUPPLIER ||
            this.props.locationList.type == OBJECT_TYPE_CUSTOMER ||
            this.props.locationList.type == OBJECT_TYPE_STORE ||
            this.props.locationList.type == OBJECT_TYPE_VENUE ||
            this.props.locationList.type == OBJECT_TYPE_WAREHOUSE ||
            this.props.locationList.type == OBJECT_TYPE_MOBILE_UNIT)) {
            return undefined;
        }

        if (this.props.productList) {
            return undefined;
        }

        if (this.props.quotationList) {
            return "All quotations";
        } else {

            if (this.isFilterForced()) {
                if (this.props.forceSalesFilter) {
                    return "Sales/Invoices";
                } else if (this.props.forcePurchaseFilter) {
                    return isV2() ? "Bills" : "Purchases"
                } else {
                    return "";
                }
            }

            return "All stock flow";
        }
    }

    getNewBtnTitle() {
        if (this.props.forcePurchaseFilter && isV2()) {
            return "New Bill"
        } else if (this.props.forceSalesFilter && isV2()) {
            return "New Invoice"
        }

        if (this.props.quotationList) {
            return "Create Quotation";
        } else if (this.props.locationList && this.props.locationList.type == OBJECT_TYPE_SUPPLIER) {
            return "Purchase";
        } else if (this.props.locationList && this.props.locationList.type == OBJECT_TYPE_CUSTOMER) {
            return "Sales";
        } else {
            return "Initiate Stock Flow";
        }
    }

    getNewPath() {
        if (this.props.forcePurchaseFilter && isV2()) {
            return "/new-bill"
        } else if (this.props.forceSalesFilter && isV2()) {
            return "/new-sales"
        }


        if (this.props.quotationList) {
            return {
                pathname: "/stock-flow-creator", defaultFlow: {
                    sourceTypeValue: SOURCE_TYPE_WAREHOUSE,
                    destinationTypeValue: DESTINATION_TYPE_CUSTOMER,
                    isQuotationValue: true
                }
            }
        } else if (this.props.locationList && this.props.locationList.type == OBJECT_TYPE_SUPPLIER) {
            return {
                pathname: "/stock-flow-creator", defaultFlow: {
                    sourceTypeValue: SOURCE_TYPE_SUPPLIER + "",
                    sourceIdValue: this.props.locationList.id
                }
            }
        } else if (this.props.locationList && this.props.locationList.type == OBJECT_TYPE_CUSTOMER) {
            return {
                pathname: "/stock-flow-creator", defaultFlow: {
                    sourceTypeValue: SOURCE_TYPE_WAREHOUSE,

                    destinationTypeValue: DESTINATION_TYPE_CUSTOMER,
                    destinationIdValue: this.props.locationList.id
                }
            }
        } else if (this.props.locationList && this.props.locationList.type == OBJECT_TYPE_STORE) {
            return {
                pathname: "/stock-flow-creator", defaultFlow: {
                    sourceTypeValue: SOURCE_TYPE_STORE,
                    sourceIdValue: this.props.locationList.id
                }
            }
        } else if (this.props.locationList && this.props.locationList.type == OBJECT_TYPE_VENUE) {
            return {
                pathname: "/stock-flow-creator", defaultFlow: {
                    sourceTypeValue: SOURCE_TYPE_WAREHOUSE,

                    destinationTypeValue: DESTINATION_TYPE_VENUE,
                    destinationIdValue: this.props.locationList.id
                }
            }
        } else if (this.props.locationList && this.props.locationList.type == OBJECT_TYPE_WAREHOUSE) {
            return {
                pathname: "/stock-flow-creator", defaultFlow: {
                    sourceTypeValue: SOURCE_TYPE_WAREHOUSE,
                    sourceIdValue: this.props.locationList.id
                }
            }
        } else if (this.props.locationList && this.props.locationList.type == OBJECT_TYPE_MOBILE_UNIT) {
            return {
                pathname: "/stock-flow-creator", defaultFlow: {
                    sourceTypeValue: SOURCE_TYPE_MOBILE_UNIT,
                    sourceIdValue: this.props.locationList.id
                }
            }
        } else if (this.props.productList) {
            return {
                pathname: "/stock-flow-creator", defaultFlow: {
                    itemsValue: [
                        {
                            itemId: this.props.productList.id,
                            itemType: OBJECT_TYPE_PRODUCT
                        }
                    ]
                }
            }
        } else {
            return "/stock-flow-creator";
        }
    }

    getPath() {
        return "/stock-flow/";
    }

    isLoadAll() {
        //return !this.props.report && !this.props.quotationList && !this.props.locationList && !this.props.productList;
        return !this.props.report && !this.props.quotationList;
    }

    componentDidMount() {
        if (!this.isLoadAll()) {
            this.setState({ loading: true })
            const listener = response => {
                this.setState({ loading: false, rows: response.payload !== null ? response.payload.stockFlows : [], totalStock: response.payload !== null ? response.payload.totalStock : 0 })
                if (response.payload === null) {
                    UIUtil.showError(response.message);
                }
            }

            if (this.props.report) {
                listener({
                    status: true,
                    payload: {
                        stockFlows: this.props.report.items,
                        totalStock: 0,
                    },
                })
            } else if (this.props.quotationList) {
                Api.getQuotations(listener);
            } else if (this.props.locationList) {
                Api.getLocationStockFlows(this.props.locationList.id, listener);
            } else if (this.props.productList) {
                Api.getProductStockFlows(this.props.productList.id, listener);
            } else {
                //Api.getAllStockFlows(listener)
                listener({ status: false })
            }
        }
    }

    getHeaders() {
        if (this.state.typeFilter === FILTER_TYPE_PURCHASES) {
            return NEW_PURCHASES_HEADERS;
        } else if (this.state.typeFilter === FILTER_TYPE_SALES) {
            return NEW_SALES_HEADERS;
        }

        if (this.props.salesReport) {
            return SALES_HEADERS;
        } else if (this.props.purchasesReport) {
            return PURCHASES_HEADERS;
        } else {
            return HEADERS;
        }
    }

    getCellHeader(cell) {
        const headers = this.getHeaders();
        for (const header of headers) {
            if (header.key == cell.info.header) {
                return header;
            }
        }
    }

    getCellRow(cell) {
        const id = cell.id.split(":")[0];
        for (const row of this.state.rows) {
            if (row.id == id) {
                return row;
            }
        }
    }

    actuallyRenderCell(key, value, row) {
        //const header = this.getCellHeader(cell);
        //const row = this.getCellRow(cell);
        const cell = { value }

        //switch (header.key) {
        switch (key) {
            case "print":
                //return <LazySalesReceiptDownload stockFlowId={row.id} />

                if (Util.isNumberExist(row.saleId)) {
                    return (
                        <Link to={"/sale/" + row.saleId} target="_blank">
                            <Button onClick={e => e.stopPropagation()} kind="primary" size="sm" renderIcon={Certificate16}>Sale Voucher</Button>
                        </Link>
                    )
                } else {
                    return (
                        <div style={{ display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
                            {Util.isStringExists(row?.savedPosPrint?.print) && <Button kind="primary" size="sm" style={{ width: 120 }} onClick={e => {
                                e.stopPropagation()
                                e.preventDefault();
                                MainApp.instance.thermalPrinter.printReceipt(JSON.parse(row.savedPosPrint.print));
                            }} renderIcon={Receipt16}>Thermal</Button>}

                            {!Util.isStringExists(row?.savedPosPrint?.print) && Util.isNumberExist(row.salesOrderId) &&
                                <Button kind="primary" size="sm" style={{ width: 120 }} onClick={async e => {
                                    e.stopPropagation()
                                    e.preventDefault();

                                    const app = MainApp.instance;

                                    if (app?.thermalPrinter?.driver?.canPrintRestaurantDocs?.()) {
                                        const stopLoading = await UIUtil.pageLoader();

                                        try {
                                            const order = await Api.asyncGetSalesOrder(row.salesOrderId);
                                            await app.thermalPrinter.driver.printBill(order)
                                            UIUtil.showSuccess();
                                        } catch (e) {
                                            UIUtil.showError()
                                        } finally {
                                            stopLoading();
                                        }
                                    } else {
                                        UIUtil.showInfo("Printer not connected")
                                    }

                                }} renderIcon={Receipt16}>Thermal</Button>}

                            {hasCapabilitySupport("extendedPosCheckout") ? (<>
                                <Button kind="secondary" size="sm" style={{ width: 120 }} onClick={e => {
                                    e.stopPropagation()
                                    e.preventDefault();
                                    openPdf('TaxInvoice', row.id)
                                }} renderIcon={Receipt16}>PDF</Button>
                                <Button kind="tertiary" size="sm" style={{ width: 120 }} onClick={e => {
                                    e.stopPropagation()
                                    e.preventDefault();
                                    sharePdf('TaxInvoice', row.id)
                                }} renderIcon={Share16}>Share</Button>
                            </>) : (<>
                                <StockFlowInvoiceBtn stockFlowId={row.id} mini />
                            </>)}
                        </div>
                    )
                }
            // return <Button onClick={e => {
            //     e.stopPropagation()
            //     e.preventDefault()
            //     printSalesReceipt(row)
            // }} kind="secondary" size="sm" renderIcon={Receipt16}>Print Receipt</Button>

            case "id":
                return Util.getVoucherNumber(cell.value)

            case "sourceId":
                if (row.sourceType === SOURCE_TYPE_VENUE && row.destinationType === DESTINATION_TYPE_CUSTOMER) {
                    return (
                        <div style={{ display: 'flex', alignItems: 'center', paddingTop: '0.5rem', paddingBottom: '0.5rem' }}>
                            {this.getSourceCard(row)}
                            <DirectionStraightRight16 style={{ marginLeft: '1rem', marginRight: '1rem' }} />
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', minWidth: 125 }}>
                                {(!this.isSourceExternal(row) && !this.isDestinationExternal(row)) ? (
                                    <Tag renderIcon={Delivery16}>Stock Transfer</Tag>
                                ) : (<>
                                    <DirectionIndicator inward={this.isSourceExternal(row)} />
                                </>)}
                            </div>
                            <DirectionStraightRight16 style={{ marginLeft: '1rem', marginRight: '1rem' }} />

                            <RadioItem icon={BuildingInsights_132} title="Venue Sales" />
                            {/* {this.getDestinationCard(row)} */}
                        </div>
                    )

                } else {
                    return (
                        <div style={{ display: 'flex', alignItems: 'center', paddingTop: '0.5rem', paddingBottom: '0.5rem' }}>
                            {this.getSourceCard(row)}
                            <DirectionStraightRight16 style={{ marginLeft: '1rem', marginRight: '1rem' }} />
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', minWidth: 125 }}>
                                {(!this.isSourceExternal(row) && !this.isDestinationExternal(row)) ? (
                                    <Tag renderIcon={Delivery16}>Stock Transfer</Tag>
                                ) : (<>
                                    <DirectionIndicator inward={this.isSourceExternal(row)} />
                                </>)}
                            </div>
                            <DirectionStraightRight16 style={{ marginLeft: '1rem', marginRight: '1rem' }} />
                            {this.getDestinationCard(row)}
                        </div>
                    )
                }
            case "destinationId":
                return <> {this.getStockFlowType(row)} {Util.isNumberExist(row.returnFlowId?.length) && <p style={{ color: 'red', marginLeft: '0.25rem', fontSize: 12 }}>(Returned)</p>} </>

            case "totalStockCount":
                if (Util.isNumberExist(row.specificTotalStockCount)) {
                    return (
                        <div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
                                <DeliveryParcel16 /> {row.specificTotalStockCount} unit{row.specificTotalStockCount != 1 && 's'}
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '0.25rem', opacity: 0.65, marginTop: '0.25rem' }}>
                                Total: {cell.value} unit{cell.value != 1 && 's'}
                            </div>
                        </div>
                    )
                } else {
                    return <> <DeliveryParcel16 /> {cell.value} unit{cell.value != 1 && 's'}</>
                }

            case "initiationType":
                if (row.initiationType == STOCK_FLOW_INITIATION_TYPE_POS) {
                    return <> <Calculator16 /> POS </>
                } else if (row.initiationType == STOCK_FLOW_INITIATION_TYPE_SALES_CART) {
                    return <> <ShoppingCartPlus16 /> Sales Cart </>
                } else {
                    return <> <SettingsCheck16 /> Backend </>
                }

            case "initiationDate":
                return <> <Calendar16 /> {Util.getDate(cell.value)} </>

            case "purchaseSupplierInvoiceDate":
                return <> <Calendar16 /> {Util.getDateOnly(cell.value)} </>

            case "amount":
                //return Util.isNumberExist(cell.value) ? <> AED {parseFloat(cell.value).toFixed(2)} </> : '-'
                return Util.isNumberExist(cell.value) ? <> AED {Util.formatMoney(cell.value)} </> : '-'

            case "destinationName":
                if (row.destinationName === "Guest" && Util.isStringExists(row.salesOrderShippingFullName)) {
                    return row.salesOrderShippingFullName;
                } else {
                    return row.destinationName;
                }

            case "creationAndInvoiceDate":
                return (
                    <div style={{ paddingBlock: '0.25rem' }}>
                        <label style={{ marginBottom: 0 }} className="bx--label">Creation Date</label>
                        <br />
                        <span>{Util.getDate(row.initiationDate)}</span>
                        {Util.isNumberExist(row.purchaseSupplierInvoiceDate) && <>
                            <br />
                            <label style={{ marginBottom: 0 }} className="bx--label">Supplier Invoice Date</label>
                            <br />
                            <span><strong>{Util.getDate(row.purchaseSupplierInvoiceDate)}</strong></span>
                        </>}
                    </div>
                )

            case "salesDate":
                if (Util.isNumberExist(row.salesOrderId)) {
                    return (
                        <div style={{ paddingBlock: '0.25rem' }}>
                            <label style={{ marginBottom: 0 }} className="bx--label">Sales Order</label>
                            <br />
                            <span>{Util.getDate(row.salesOrderDate)}</span>


                            <br />
                            <label style={{ marginBottom: 0 }} className="bx--label">Order Finalized</label>
                            <br />
                            <span><strong>{Util.getDate(row.initiationDate)}</strong></span>
                        </div>
                    )
                } else {
                    return <> <Calendar16 /> {Util.getDate(row.initiationDate)} </>
                }

            default:
                return cell.value;
        }
    }

    renderCell(cell) {
        const header = this.getCellHeader(cell);
        const row = this.getCellRow(cell);

        return this.actuallyRenderCell(header.key, cell.value, row)
    }

    renderSideView() {
        return (
            <>
                {this.state.selectedStockFlowId && <SidePanel md2 onClose={() => this.setState({ selectedStockFlowId: undefined })}>
                    <LockScroll />
                    <StockFlowItemPage {...this.props} fullWidth pagePathParamsOverride={{ stockFlowId: this.state.selectedStockFlowId }} overrideBacktToListBtn={() => this.setState({ selectedStockFlowId: undefined })} />
                </SidePanel>}
            </>
        )
    }

    render() {
        if (this.state.loading) {
            return (
                <div style={{ width: '100%' }}>
                    <DataTableSkeleton />
                </div>
            )
        }

        if (this.isLoadAll()) {
            return (
                <div className='tooltip-supported-toolbar' style={{ width: '100%' }}>
                    <PaginatedDataTable
                        reloadRequest={this.state.idFilter + ':fi-all-stock-floww-list-fr:' + this.state.voucherNoFilter + "--type-filter" + this.state.typeFilter + "--location-filter" + this.state.locationFilter}
                        title={this.getTitle()} description={this.getSubtitle()}
                        loader={(page, sortRequest, listener) => Api.getAllStockFlows(page, {
                            filterId: (() => {
                                if (this.state.voucherNoFilter.length > 0) {
                                    if (isNaN(this.state.voucherNoFilter) || (this.state.voucherNoFilter.trim() == "")) {
                                        return 0;
                                    }
                                }

                                const id = Util.getIdFromVoucherNumber(parseInt(this.state.voucherNoFilter));
                                return (isNaN(id) || !Util.isStringExists(this.state.voucherNoFilter)) ? -1 : id;
                            })(),
                            filterRecordId: this.state.idFilter,
                            filterFlowType: this.state.typeFilter,
                            filterLocationId: this.props.locationList?.id || this.state.locationFilter,
                            filterProductId: this.props.productList?.id
                        }, listener)}
                        loadResponseHandler={response => {
                            if (typeof response.payload.totalStock === 'number') {
                                this.setState({ totalStock: response.payload.totalStock })
                            }

                            return ({ loadedItems: response.payload.stockFlows, loadedHasMore: response.payload.paginationHasMore })
                        }}
                        columns={this.getHeaders().map(header => ({
                            id: header.key,
                            name: header.header,
                            render: item => this.actuallyRenderCell(header.key, item[header.key], item)
                        }))}
                        onClick={row => {
                            this.setState({ selectedStockFlowId: row.id })
                            // this.props.history.push(this.getPath() + row.id)
                        }}
                    >
                        {!this.props.hideToolbar && <TableToolbar style={{ zIndex: 2 }}>
                            <TableToolbarContent>
                                {this.renderFilterTab()}

                                {!this.props.locationList?.id ? <ApiComboBoxField
                                    apiFn={Api.getStoresAndWarehouses}
                                    size="lg"
                                    placeholder="Store/Warehouse"
                                    value={this.state.locationFilter}
                                    setValue={locationFilter => this.setState({ locationFilter })}
                                /> : null}

                                <ExpandableSearch
                                    style={{ minWidth: 48 }}
                                    renderIcon={<div style={{ width: 48, height: 48, minWidth: 48, display: 'flex', justifyContent: 'center', alignItems: 'center', pointerEvents: 'none' }}>
                                        <Receipt16 />
                                    </div>}
                                    placeholder={"Find by Voucher No"} onChange={e => this.setState({ voucherNoFilter: e.target.value })} value={this.state.voucherNoFilter} />
                                <ExpandableSearch placeholder={"Find by ID"}
                                    style={{ minWidth: 48 }}
                                    renderIcon={<div style={{ width: 48, height: 48, minWidth: 48, display: 'flex', justifyContent: 'center', alignItems: 'center', pointerEvents: 'none' }}>
                                        <Identification16 />
                                    </div>}
                                    onChange={e => this.setState({ idFilter: e.target.value })} value={this.state.idFilter} />
                                <Link to={this.getNewPath()} style={{ textDecoration: 'none' }}>
                                    <Button style={{ minWidth: 250 }} renderIcon={Add16}>{this.getNewBtnTitle()}</Button>
                                </Link>
                            </TableToolbarContent>
                        </TableToolbar>}
                    </PaginatedDataTable>

                    {this.renderSideView()}
                </div>
            )
        }
        // return <PaginatedDataTable loader={(page, listener) => Api.getAllStockFlows(page, listener)} />

        return (
            <div style={{ width: '100%' }}>
                <DataTable
                    //rows={Util.isStringExists(this.state.idFilter) ? this.state.rows.filter(row => row.stockFlowRecordId == this.state.idFilter) : this.state.rows} 
                    rows={Util.isStringExists(this.state.idFilter) ? this.state.rows.filter(row => row.stockFlowRecordId == this.state.idFilter) : (Util.isStringExists(this.state.voucherNoFilter) ? this.state.rows.filter(row => row.id == Util.getIdFromVoucherNumber(this.state.voucherNoFilter)) : this.state.rows)}


                    headers={this.getHeaders()} isSortable>
                    {({
                        rows,
                        headers,
                        getHeaderProps,
                        getRowProps,
                        getTableProps,
                    }) => (
                        <TableContainer title={this.getTitle()} description={this.getSubtitle()}>
                            {!this.props.hideToolbar && <TableToolbar>
                                <TableToolbarContent>
                                    <ExpandableSearch
                                        style={{ minWidth: 48 }}
                                        renderIcon={<div style={{ width: 48, height: 48, display: 'flex', justifyContent: 'center', alignItems: 'center', pointerEvents: 'none' }}>
                                            <Receipt16 />
                                        </div>}
                                        placeholder={"Find by Voucher No"} onChange={e => this.setState({ voucherNoFilter: e.target.value })} value={this.state.voucherNoFilter} />
                                    <ExpandableSearch placeholder={"Find by ID"}
                                        renderIcon={<div style={{ width: 48, height: 48, display: 'flex', justifyContent: 'center', alignItems: 'center', pointerEvents: 'none' }}>
                                            <Identification16 />
                                        </div>}
                                        onChange={e => this.setState({ idFilter: e.target.value })} value={this.state.idFilter} />
                                    <Link to={this.getNewPath()} style={{ textDecoration: 'none' }}>
                                        <Button style={{ minWidth: 250 }} renderIcon={Add16}>{this.getNewBtnTitle()}</Button>
                                    </Link>
                                </TableToolbarContent>
                            </TableToolbar>}
                            <Table {...getTableProps()}>
                                <TableHead>
                                    <TableRow>
                                        {headers.map((header) => (
                                            <TableHeader key={header.key} {...getHeaderProps({ header })}>
                                                {header.header}
                                            </TableHeader>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row) => (
                                        <TableRow onClick={(() => {
                                            this.setState({ selectedStockFlowId: row.id })
                                            // this.props.history.push(this.getPath() + row.id)
                                        })}
                                            key={row.id} {...getRowProps({ row })}>
                                            {row.cells.map((cell, index) => (
                                                <TableCell
                                                    style={{ textAlign: (index == 6 && this.props.salesReport) ? 'end' : undefined }}
                                                    key={cell.id}>{this.renderCell(cell)}</TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            {<TableToolbar>
                                {/* <Pagination pageSizes={[10, 20, 30, 40, 50]} /> */}
                            </TableToolbar>}
                        </TableContainer>
                    )}
                </DataTable>

                {this.renderSideView()}
            </div>
        )
    }

    isQuotation(stockFlow) {
        //return stockFlow.quotation && !Util.isNumberExist(stockFlow.transactionId);
        return stockFlow.quotation && (stockFlow.transactionIds == null || stockFlow.transactionIds.length == 0);
    }

    getStockFlowType(stockFlow) {
        let subtitle;
        if (stockFlow.sourceType == SOURCE_TYPE_SUPPLIER && !this.isDestinationExternal(stockFlow)) {
            subtitle = stockFlow.pastPurchase ? "Past Purchase" : "Purchase";
        } else if (!this.isSourceExternal(stockFlow) && stockFlow.destinationType == DESTINATION_TYPE_CUSTOMER) {
            subtitle = this.isQuotation(stockFlow) ? "Quotation" : "Sales";
        } else if (!this.isSourceExternal(stockFlow) && stockFlow.destinationType == DESTINATION_TYPE_SUPPLIER) {
            subtitle = "Purchase Return"
        } else if (stockFlow.sourceType == SOURCE_TYPE_CUSTOMER && !this.isDestinationExternal(stockFlow)) {
            if (stockFlow.rentalReturn) {
                subtitle = "Rental Return"
            } else {
                subtitle = "Sales Return";
            }
        } else if ((!this.isSourceExternal(stockFlow) && !this.isDestinationExternal(stockFlow))) {
            subtitle = "Stock Transfer";
        } else {
            subtitle = "-"
        }

        return subtitle;
    }

    getSourceCard(stockFlow) {
        if (stockFlow.producedInProduction) {
            return <RadioItem icon={Industry32} title="Production" desc="Production output" />
        }

        switch (stockFlow.sourceType) {
            case SOURCE_TYPE_SUPPLIER:
                return <RadioItem icon={Delivery32} title="Supplier" desc={stockFlow.sourceName} />
            case SOURCE_TYPE_WAREHOUSE:
                return <RadioItem icon={DataVis_232} title="Warehouse" desc={stockFlow.sourceName} />
            case SOURCE_TYPE_STORE:
                return <RadioItem icon={Store32} title="Store" desc={stockFlow.sourceName} />
            case SOURCE_TYPE_VENUE:
                return <RadioItem icon={Building32} title="Venue" desc={stockFlow.sourceName} />
            case SOURCE_TYPE_STOCK_CREATION:
                return <RadioItem icon={Cube32} title="Create" desc="Created stock" />
            case SOURCE_TYPE_CUSTOMER:
                return <RadioItem icon={AirlinePassengerCare32} title="Customer" desc={stockFlow.sourceName} />
            case SOURCE_TYPE_MOBILE_UNIT:
                return <RadioItem icon={Van32} title="Mobile Unit" desc={stockFlow.sourceName} />
        }

        return undefined;
    }

    getDestinationCard(stockFlow) {
        if (stockFlow.usedInProduction) {
            return <RadioItem icon={Industry32} title="Production" desc="Used in production" />
        }

        switch (stockFlow.destinationType) {
            case DESTINATION_TYPE_SUPPLIER:
                return <RadioItem icon={Delivery32} title="Supplier" desc={stockFlow.destinationName} />
            case DESTINATION_TYPE_CUSTOMER:
                return <RadioItem icon={AirlinePassengerCare32} title="Customer" desc={stockFlow.destinationName} />
            case DESTINATION_TYPE_WAREHOUSE:
                return <RadioItem icon={DataVis_232} title="Warehouse" desc={stockFlow.destinationName} />
            case DESTINATION_TYPE_STORE:
                return <RadioItem icon={Store32} title="Store" desc={stockFlow.destinationName} />
            case DESTINATION_TYPE_VENUE:
                return <RadioItem icon={Building32} title="Venue" desc={stockFlow.destinationName} />
            case DESTINATION_TYPE_STOCK_TERMINATION:
                return <RadioItem icon={CheckboxIndeterminate32} title="Terminate" desc="Terminated stock" />
            case DESTINATION_TYPE_MOBILE_UNIT:
                return <RadioItem icon={Van32} title="Mobile Unit" desc={stockFlow.destinationName} />
        }

        return undefined;
    }

    isSourceExternal(stockFlow) {
        return stockFlow.sourceType == SOURCE_TYPE_SUPPLIER || stockFlow.sourceType == SOURCE_TYPE_STOCK_CREATION || stockFlow.sourceType == SOURCE_TYPE_CUSTOMER;
    }

    isDestinationExternal(stockFlow) {
        return stockFlow.destinationType == DESTINATION_TYPE_SUPPLIER || stockFlow.destinationType == DESTINATION_TYPE_CUSTOMER || stockFlow.destinationType == DESTINATION_TYPE_STOCK_TERMINATION;
    }

}

export default withRouter(StockFlowListView);