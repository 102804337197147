import { ACCOUNT_TYPE_ADMINISTRATION, PAYMENT_METHOD_CASH, PAYMENT_METHOD_CREDIT, PAYMENT_METHOD_DEBIT, POS_BARCODE_SCAN_TYPE_COUPON, POS_BARCODE_SCAN_TYPE_CUSTOMER, POS_BARCODE_SCAN_TYPE_PRODUCT, POS_SESSION_DISCOUNT_TYPE_LOYALTY } from "../../../constants/Constants";
import { printPosReceipt } from "../../../pdfs/pos-receipt/PosReceiptPdf";
import Api, { createApiWithHeaders } from "../../../session/Api";
import { getPosLayout, hasTerminal, isPosEditMode, setPosLayout, setPosEditMode, getTerminal, getAccountRole } from "../../../session/SessionManager";
import UIUtil from "../../../util/UIUtil";
import Util, { big } from "../../../util/Util";

import ReactTooltip from 'react-tooltip';
import SocketSession, { SOCKET_EVENT_POS_MONITORING_INPUT, SOCKET_EVENT_POS_SESSION } from "../../../session/SocketSession";

import { toast } from 'react-toastify';
import { hasCapabilitySupport } from "../../../app/Capabilities";
import { REST_ORDER_TYPE } from "../../../domain/rest-sales-order";
import { SHOULD_SEND_POS_RECEIPT_SMS } from "../../../app/Config";
import { renderNode } from "../../../components/imperative-render";
import DeliverableItemPage from "../../sales-order/DeliverableItemPage";
import { LockScroll } from "../../../hooks/useLockScroll";
import { SidePanel } from "../../../templates/draft/components/side-panel";

export const SESSION_EVENT_TYPE_SESSION_END = 0;

const DEFAULT_POS_LAYOUT = [
    {
        id: "cart-content",
        grid: { x: 0, y: 0, w: 8, h: window.innerHeight < 800 ? 12 : 13 }
    },
    {
        id: "payment-widget",
        grid: { x: 0, y: 14, w: 8, h: window.innerHeight < 800 ? 3 : 2 }
    },
    {
        id: "product-viewer",
        grid: { x: 0, y: 17, w: 8, h: 8 }
    },
    {
        id: "pos-info",
        grid: { x: 9, y: 0, w: 4, h: window.innerHeight < 800 ? 3 : 2 }
    },
    {
        id: "sales-info",
        grid: { x: 9, y: 0, w: 4, h: window.innerHeight < 800 ? 3 : 2 }
    },
    {
        id: "customer-info",
        grid: { x: 9, y: 0, w: 4, h: 5 }
    },
    {
        id: "coupon-widget",
        grid: { x: 9, y: 7, w: 4, h: window.innerHeight < 800 ? 9 : 7 }
    },
    {
        id: "barcode-input",
        grid: { x: 9, y: 16, w: 4, h: window.innerHeight < 800 ? 3 : 7 }
    },
]


export const getDefaultPosState = () => {
    const savedPosLayout = getPosLayout();
    return {
        loadingPos: false,
        loadingPosAction: false,

        hasNoTerminalError: false,
        inError: false,

        posSession: null,




        showEndPosDialog: false,
        endPosAmount: 0,

        showGoOnBreakConfirm: false,
        showResumeFromBreakPos: false,
        resumePosClearanceCode: "",
        loadingPosSetOnBreak: false,

        showPosInfoDialog: false,
        showCustomerCreatorDialog: false,
        creatingCustomerLoading: false,

        isSetSalespersonLoading: false,
        isSetWaiterLoading: false,
        isSetNoOfPeopleDineInLoading: false,
        isSetTableLoading: false,
        isSetRestOrderTypeLoading: false,

        loadingCartUpdate: false,
        showQuantitySetDialog: undefined,
        quantitySetValue: 0,
        settingQuantityLoading: false,

        showHighlightProductDialog: false,
        highlightProductBarcodeValue: "",
        highlightProductRequestBarcode: "",

        showHeldCartsDialog: false,
        heldCarts: [],


        showCheckoutDialog: false,
        showDineInCheckoutDialog: false,
        showTakeOutCheckoutDialog: false,
        showDeliveryCheckoutDialog: false,
        checkoutLoading: false,


        loadingAddingDiscount: false,
        loadingAddingCoupon: false,
        showCouponSelectDialog: false,
        couponSelectCodeValue: "",


        freeFormDiscountCartItemOnly: null,
        freeFormDiscountPercMode: false,
        loadingAddingFreeFormDiscount: false,
        showFreeFormDiscountDialog: false,
        freeFormDiscountAmountValue: "",
        freeFormDiscountClearanceCodeValue: "",



        posEditMode: isPosEditMode(),
        posLayout: savedPosLayout !== null ? JSON.parse(savedPosLayout) : DEFAULT_POS_LAYOUT,
        posLayoutKey: Util.newTempId(),

        sessionUpdateId: 0,




        showTicketSelector: false,
        ticketSelectorDefaultValues: undefined,




        showSetCustomPriceDialog: false,
        setCustomPriceCartItem: null,

        removingCartItemIds: [],
        changingCartItemQtyReqs: [],

        receiptToPrint: undefined,

        // restOrderType: "dine-in",
    }
}

let CART_ID_CACHE = []

class PosState {

    notificationAudio = new Audio("notification.wav");
    readOnly;
    monitoringSession;
    isCartSession;

    static cartSession(getState, setState, cartId, { onClose }) {
        const state = new PosState(getState, setState, undefined, createApiWithHeaders({
            "rbt-pos-override-cart-id": cartId
        }))
        state.isCartSession = true;
        state.createRestCart = listener => {
            listener(true)
            onClose()
        }
        const clearCart = state.clearCart.bind(state);
        state.clearCart = listener => clearCart(success => {
            listener(success);
            if (success) {
                onClose()
            }
        })

        const createSalesOrder = state.createSalesOrder.bind(state);
        state.createSalesOrder = (orderType, req, successListener, errorListener) => createSalesOrder(orderType, req, () => {
            onClose();
            successListener?.();
        }, errorListener)

        const createSalesOrderWithoutPrint = state.createSalesOrderWithoutPrint.bind(state);
        state.createSalesOrderWithoutPrint = (orderType, req, successListener, errorListener) => createSalesOrderWithoutPrint(orderType, req, () => {
            onClose();
            successListener?.();
        }, errorListener)
        return state;
    }

    constructor(getState, setState, mainApp, api = Api) {
        this.getState = getState;
        this.setState = setState;
        this.mainApp = mainApp;
        this.api = api;

        const terminal = getTerminal();
        if (terminal) {
            this.api.openNewCustomerDialog(terminal.id, false, () => { });
        }
    }

    restartPosLayout() {
        setPosLayout(null);
        this.setState({ posLayout: DEFAULT_POS_LAYOUT, posLayoutKey: Util.newTempId() })
    }

    togglePosEditMode() {
        this.setState(prevState => {
            let posEditMode = !prevState.posEditMode;
            setPosEditMode(posEditMode);
            return { posEditMode }
        })
    }

    setLoadingAddingCoupon(loadingAddingCoupon) {
        this.setState({ loadingAddingCoupon })
    }

    isLoadingAddingCoupon() {
        return this.getState().loadingAddingCoupon;
    }

    setCouponSelectCodeValue(couponSelectCodeValue) {
        this.setState({ couponSelectCodeValue })
    }

    getCouponSelectCodeValue() {
        return this.getState().couponSelectCodeValue;
    }

    setShowCouponSelectDialog(showCouponSelectDialog) {
        if (showCouponSelectDialog) {
            this.setState({ showCouponSelectDialog, couponSelectCodeValue: "" })
        } else {
            this.setState({ showCouponSelectDialog })
        }
    }

    isShowCouponSelectDialog() {
        return this.getState().showCouponSelectDialog;
    }

    setLoadingAddingDiscount(loadingAddingDiscount) {
        this.setState({ loadingAddingDiscount })
    }

    isLoadingAddingDiscount() {
        return this.getState().loadingAddingDiscount;
    }

    getLoyaltyPointDiscount() {
        for (const discount of this.getSession().discounts) {
            if (discount.type == POS_SESSION_DISCOUNT_TYPE_LOYALTY) {
                return discount;
            }
        }

        return undefined;
    }







    setLoadingAddingFreeFormDiscount(loadingAddingFreeFormDiscount) {
        this.setState({ loadingAddingFreeFormDiscount })
    }

    isLoadingAddingFreeFormDiscount() {
        return this.getState().loadingAddingFreeFormDiscount;
    }

    setFreeFormDiscountAmountValue(freeFormDiscountAmountValue) {
        this.setState({ freeFormDiscountAmountValue })
    }

    getFreeFormDiscountAmountValue() {
        return this.getState().freeFormDiscountAmountValue;
    }

    setFreeFormDiscountClearanceCodeValue(freeFormDiscountClearanceCodeValue) {
        this.setState({ freeFormDiscountClearanceCodeValue })
    }

    getFreeFormDiscountClearanceCodeValue() {
        return this.getState().freeFormDiscountClearanceCodeValue;
    }

    setShowFreeFormDiscountDialog(showFreeFormDiscountDialog) {
        if (showFreeFormDiscountDialog) {
            this.setState({ showFreeFormDiscountDialog, freeFormDiscountAmountValue: "", freeFormDiscountClearanceCodeValue: "", freeFormDiscountPercMode: false, freeFormDiscountCartItemOnly: null, })
        } else {
            this.setState({ showFreeFormDiscountDialog })
        }
    }

    setShowFreeFormDiscountDialogForProduct(showFreeFormDiscountDialog, cartItem) {
        if (showFreeFormDiscountDialog) {
            this.setState({ showFreeFormDiscountDialog, freeFormDiscountAmountValue: cartItem.discountAmount ?? cartItem.discountPerc ?? "", freeFormDiscountClearanceCodeValue: "", freeFormDiscountPercMode: cartItem.discountPerc ? true : false, freeFormDiscountCartItemOnly: cartItem, })
        } else {
            this.setState({ showFreeFormDiscountDialog })
        }
    }

    isShowFreeFormDiscountDialog() {
        return this.getState().showFreeFormDiscountDialog;
    }



    addFreeFormDiscount() {
        if (this.__addFreeFormDiscount_running) {
            return;
        }

        this.__addFreeFormDiscount_running = true;
        if (this.salesOrderSession) {
            this.setState({ loadingAddingFreeFormDiscount: true })
            this.api.addFreeFormDiscountForSalesOrder(this.getState().freeFormDiscountAmountValue, this.getState().freeFormDiscountClearanceCodeValue, this.getState().freeFormDiscountPercMode, this.salesOrderId, response => {
                try {
                    if (response.status === true) {
                        const { a: session, b: order } = response.payload;

                        this.setState({ showFreeFormDiscountDialog: false }, () => this.setState({ loadingAddingFreeFormDiscount: false }))
                        this.onUpdateSession(session)
                        this.updateOrder?.(order);
                        UIUtil.showSuccess();
                    } else {
                        this.setState({ loadingAddingFreeFormDiscount: false })
                        UIUtil.showError(response.message);
                    }
                } finally {
                    this.__addFreeFormDiscount_running = false
                }
            })
        } else if (this.monitoringSession) {
            this.setState({ loadingAddingFreeFormDiscount: true })
            this.api.addFreeFormDiscountFromAdmin(this.getState().freeFormDiscountAmountValue, this.getState().freeFormDiscountClearanceCodeValue, this.getState().freeFormDiscountPercMode, this.getState().freeFormDiscountCartItemOnly?.id, this.getSession().operatorId, this.getSession().terminalId, response => {
                try {
                    if (response.status === true) {
                        this.setState({ showFreeFormDiscountDialog: false }, () => this.setState({ loadingAddingFreeFormDiscount: false }))
                        this.onUpdateSession(response.payload)
                        UIUtil.showSuccess();
                    } else {
                        this.setState({ loadingAddingFreeFormDiscount: false })
                        UIUtil.showError(response.message);
                    }
                } finally {
                    this.__addFreeFormDiscount_running = false
                }
            })
        } else {
            this.setState({ loadingAddingFreeFormDiscount: true })
            this.api.addFreeFormDiscount(this.getState().freeFormDiscountAmountValue, this.getState().freeFormDiscountClearanceCodeValue, this.getState().freeFormDiscountPercMode, this.getState().freeFormDiscountCartItemOnly?.id, response => {
                this.__addFreeFormDiscount_running = false
                try {
                    if (response.status === true) {
                        this.setState({ showFreeFormDiscountDialog: false }, () => this.setState({ loadingAddingFreeFormDiscount: false }))
                        this.onUpdateSession(response.payload)
                        UIUtil.showSuccess();
                    } else {
                        this.setState({ loadingAddingFreeFormDiscount: false })
                        UIUtil.showError(response.message);
                    }
                } finally {
                    this.__addFreeFormDiscount_running = false
                }
            })
        }
    }


    setApplyCoupon(coupon, apply, listener) {
        if (this.salesOrderSession) {
            this.setState({ loadingAddingCoupon: true })
            this.api.setApplyCouponForSalesOrder(this.salesOrderId, coupon, apply, response => {
                if (response.status === true) {
                    const { a: session, b: order } = response.payload;

                    this.setState({ loadingAddingCoupon: false, showCouponSelectDialog: false })
                    this.onUpdateSession(session)
                    this.updateOrder?.(order);
                    UIUtil.showSuccess();
                } else {
                    this.setState({ loadingAddingCoupon: false })
                    UIUtil.showError(response.message);
                }
                if (listener !== undefined) {
                    listener(response.status === true);
                }
            })
        } else {
            this.setState({ loadingAddingCoupon: true })
            this.api.setApplyCoupon(coupon, apply, response => {
                if (response.status === true) {
                    this.setState({ loadingAddingCoupon: false, showCouponSelectDialog: false })
                    this.onUpdateSession(response.payload)
                    UIUtil.showSuccess();
                } else {
                    this.setState({ loadingAddingCoupon: false })
                    UIUtil.showError(response.message);
                }
                if (listener !== undefined) {
                    listener(response.status === true);
                }
            })
        }
    }

    setApplyLoyaltyPoints(apply, listener) {
        this.setState({ loadingAddingDiscount: true })
        this.api.setApplyLoyaltyPoints(apply, response => {
            if (response.status === true) {
                this.setState({ loadingAddingDiscount: false })
                this.onUpdateSession(response.payload)
                UIUtil.showSuccess();
            } else {
                this.setState({ loadingAddingDiscount: false })
                UIUtil.showError(response.message);
            }
            if (listener !== undefined) {
                listener(response.status === true);
            }
        })
    }

    removeDiscount(discountId, listener) {
        if (this.salesOrderSession) {
            this.api.removeDiscountForSalesOrder(this.salesOrderId, discountId, response => {
                if (response.status === true) {
                    const { a: session, b: order } = response.payload;

                    this.onUpdateSession(session)
                    this.updateOrder?.(order);
                    UIUtil.showSuccess();
                } else {
                    UIUtil.showError(response.message);
                }
                if (listener !== undefined) {
                    listener(response.status === true);
                }
            })
        } else if (this.monitoringSession) {
            this.api.removeDiscountFromAdmin(this.getSession().operatorId, this.getSession().terminalId, discountId, response => {
                if (response.status === true) {
                    UIUtil.showSuccess();
                } else {
                    UIUtil.showError(response.message);
                }
                if (listener !== undefined) {
                    listener(response.status === true);
                }
            })
        } else {
            this.api.removeDiscount(this.getTerminalId(), discountId, response => {
                if (response.status === true) {
                    this.onUpdateSession(response.payload)
                    UIUtil.showSuccess();
                } else {
                    UIUtil.showError(response.message);
                }
                if (listener !== undefined) {
                    listener(response.status === true);
                }
            })
        }
    }

    getTerminalId() {
        return getTerminal().id
    }

    isSetSalespersonLoading() {
        return this.getState().isSetSalespersonLoading;
    }

    isSetWaiterLoading() {
        return this.getState().isSetWaiterLoading;
    }

    isSetTableLoading() {
        return this.getState().isSetTableLoading;
    }

    isSetNoOfPeopleDineInLoading() {
        return this.getState().isSetNoOfPeopleDineInLoading;
    }

    isSetRestOrderTypeLoading() {
        return this.getState().isSetRestOrderTypeLoading;
    }

    getSalespersonId() {
        return this.getState().posSession.salespersonId;
    }

    getWaiterId() {
        return this.getState().posSession.waiterId;
    }

    getTableId() {
        return this.getState().posSession.tableId;
    }

    getNoOfPeopleDineIn() {
        return this.getState().posSession.noOfPeopleDineIn;
    }

    replaceCartItemItemId(cartItemId, newItemId) {
        this.setState({ loadingCartUpdate: true })
        this.api.replaceCartItemItemId(cartItemId, newItemId, response => {
            if (response.status === true) {
                this.onUpdateSession(response.payload)
                UIUtil.showSuccess();
            } else {
                UIUtil.showError(response.message);
            }
            this.setState({ loadingCartUpdate: false })
        })
    }

    isAllowCustomPricing() {
        return this.getSession()?.allowCustomPrice === true;
    }

    showSetCartItemPriceDialog(cartItem) {
        this.setState({
            showSetCustomPriceDialog: true,
            setCustomPriceCartItem: cartItem
        })
    }

    closeSetCartItemPriceDialog() {
        this.setState({ showSetCustomPriceDialog: false })
    }

    isShowSetCartItemPriceDialog() {
        return this.getState().showSetCustomPriceDialog
    }

    getSetCustomPriceCartItem() {
        return this.getState().setCustomPriceCartItem;
    }

    setCartItemCustomPrice(cartItemId, customPrice, clearanceCode, onDone, onRequireClearance) {
        this.api.setCartItemCustomPrice(cartItemId, customPrice, clearanceCode, response => {
            if (response.status === true) {
                if (response.payload === "requires_clearance_code") {
                    onRequireClearance();
                    return;
                }

                this.onUpdateSessionAndExtraProp(response.payload, { showSetCustomPriceDialog: false })
                UIUtil.showSuccess();
            } else {
                UIUtil.showError(response.message);
            }
            onDone()
        })
    }

    setSalespersonId(salespersonId) {
        this.setState({ isSetSalespersonLoading: true })
        this.api.setPosSalesperson(salespersonId, response => {
            if (response.status === true) {
                this.setState({ isSetSalespersonLoading: false })
                this.onUpdateSession(response.payload)
                UIUtil.showSuccess();
            } else {
                this.setState({ isSetSalespersonLoading: false })
                UIUtil.showError(response.message);
            }
        })
    }

    setWaiterId(waiterId) {
        this.setState({ isSetWaiterLoading: true })
        this.api.setPosWaiter(waiterId, response => {
            if (response.status === true) {
                this.setState({ isSetWaiterLoading: false })
                this.onUpdateSession(response.payload)
                UIUtil.showSuccess();
            } else {
                this.setState({ isSetWaiterLoading: false })
                UIUtil.showError(response.message);
            }
        })
    }

    setTableId(tableId) {
        this.setState({ isSetTableLoading: true })
        this.api.setPosTable(tableId, response => {
            if (response.status === true) {
                this.setState({ isSetTableLoading: false })
                this.onUpdateSession(response.payload)
                UIUtil.showSuccess();
            } else {
                this.setState({ isSetTableLoading: false })
                UIUtil.showError(response.message);
            }
        })
    }

    setNoOfPeopleDineIn(noOfPeopleDineIn) {
        this.setState({ isSetNoOfPeopleDineInLoading: true })
        this.api.setPosNoOfPeopleDineIn(noOfPeopleDineIn, response => {
            if (response.status === true) {
                this.setState({ isSetNoOfPeopleDineInLoading: false })
                this.onUpdateSession(response.payload)
                UIUtil.showSuccess();
            } else {
                this.setState({ isSetNoOfPeopleDineInLoading: false })
                UIUtil.showError(response.message);
            }
        })
    }

    setOrderRestType(orderRestType) {
        if (this.isSetRestOrderTypeLoading()) {
            return;
        }

        this.setState({ isSetRestOrderTypeLoading: true })
        this.api.setPosRestOrderType(orderRestType, response => {
            if (response.status === true) {
                this.setState({ isSetRestOrderTypeLoading: false })
                this.onUpdateSession(response.payload)
                UIUtil.showSuccess();
            } else {
                this.setState({ isSetRestOrderTypeLoading: false })
                UIUtil.showError(response.message);
            }
        })
    }

    setOrderRestTypeHeadless(orderRestType, listener) {
        this.api.setPosRestOrderType(orderRestType, response => {
            if (response.status === true) {
                this.onUpdateSession(response.payload)
                listener(true)
            } else {
                listener(false)
            }
        })
    }

    // shouldCreateSalesOrderOnCheckoutBtn() {
    //     return hasCapabilitySupport("restaurant");
    // }

    async printCart() {
        const posSessionSnapshot = JSON.parse(JSON.stringify(this.getSession()));
        const receipt = { ...posSessionSnapshot, paymentMethods: [], cashbackAmount: 0 };
        this.mainApp.thermalPrinter.driver.printPosCart(receipt);
    }

    onCheckoutBtn(paymentMethods, cashbackAmount, successListener) {
        this.setState({ checkoutLoading: true });
        this.api.checkoutPos({ paymentMethods }, response => {
            if (response.status === true) {
                const posSessionSnapshot = JSON.parse(JSON.stringify(this.getSession()));
                posSessionSnapshot.lastTransactionId = response.payload.lastTransactionId;
                posSessionSnapshot.lastStockFlowRecordId = response.payload.lastStockFlowRecordId;
                posSessionSnapshot.lastStockFlowId = response.payload.lastStockFlowId;
                posSessionSnapshot._transient_param_lastSaleCustomerNumber = response.payload._transient_param_lastSaleCustomerNumber;
                posSessionSnapshot._transient_param_coupons = response.payload._transient_param_coupons;


                this.setState({ checkoutLoading: false, showCheckoutDialog: false, sessionUpdateId: Util.newTempId() });
                this.onUpdateSession(response.payload)
                UIUtil.showSuccess();
                if (successListener) {
                    successListener();
                }

                // printPosReceipt(posSessionSnapshot);
                const receipt = { ...posSessionSnapshot, paymentMethods, cashbackAmount };

                let amounts = {}
                try {
                    amounts = {
                        cashAmount: paymentMethods ? paymentMethods.filter($ => $.methodType === PAYMENT_METHOD_CASH).filter($ => $.amount).map($ => big($.amount)).reduce((t, c) => t.add(c), big(0)).round(2).toNumber() : 0,
                        cardAmount: paymentMethods ? paymentMethods.filter($ => $.methodType === PAYMENT_METHOD_DEBIT || $.methodType === PAYMENT_METHOD_CREDIT).filter($ => $.amount).map($ => big($.amount)).reduce((t, c) => t.add(c), big(0)).round(2).toNumber() : 0,
                        otherAmount: paymentMethods ? paymentMethods.filter($ => $.methodType !== PAYMENT_METHOD_CASH && $.methodType !== PAYMENT_METHOD_DEBIT && $.methodType !== PAYMENT_METHOD_CREDIT).filter($ => $.amount).map($ => big($.amount)).reduce((t, c) => t.add(c), big(0)).round(2).toNumber() : 0,
                    }
                } catch (e) { }

                this.api.savePosPrint({
                    voucherNo: Util.getVoucherNumber(receipt.lastStockFlowId),
                    customerName: Util.isStringExists(receipt.customerName) ? receipt.customerName : "Guest",
                    date: new Date().getTime(),
                    amount: receipt?.posTotals?.total,

                    ...amounts,

                    print: JSON.stringify(receipt)
                }, () => { })

                if (SHOULD_SEND_POS_RECEIPT_SMS) {
                    this.setState({ receiptToPrint: receipt })
                } else {
                    if (this.mainApp) {
                        this.mainApp.thermalPrinter.printReceipt(receipt)
                    }
                }

            } else {
                this.setState({ checkoutLoading: false });
                UIUtil.showError(response.message);
            }
        })
    }

    createSalesOrder(orderType, req, successListener, errorListener) {
        if (this.getState().checkoutLoading) {
            return;
        }

        this.setState({ checkoutLoading: true });
        this.api.posCreateSalesOrder({
            orderType: orderType,
            ...req
        }, response => {
            if (response.status === true) {
                this.setState({ checkoutLoading: false, showCheckoutDialog: false, showTakeOutCheckoutDialog: false, showDineInCheckoutDialog: false, showDeliveryCheckoutDialog: false, sessionUpdateId: Util.newTempId() });
                this.onUpdateSession(response.payload)
                UIUtil.showSuccess();
                if (successListener) {
                    successListener();
                }

                try {
                    if (this.mainApp?.thermalPrinter?.driver?.canPrintRestaurantDocs?.()) {
                        this.mainApp.thermalPrinter.driver.printBill(response.payload._transient_param_salesOrder, req.paymentBeforeInvoice)
                    }
                } catch (e) { }

                if (hasCapabilitySupport("restaurant")) {
                    this.setState({ tab: "orders" })
                }

                if (hasCapabilitySupport("extendedPosCheckout")) {
                    renderNode(onClose => (
                        <SidePanel md2 backBtn onClose={onClose}>
                            <LockScroll />
                            <DeliverableItemPage orderIdOverride={response.payload._transient_param_salesOrder.id} mainApp={this.mainApp} />
                        </SidePanel>
                    ))
                }
            } else {
                this.setState({ checkoutLoading: false });
                UIUtil.showError(response.message);
                if (errorListener) {
                    errorListener();
                }
            }
        })
    }

    createSalesOrderWithoutPrint(orderType, req, successListener, errorListener) {
        if (this.getState().checkoutLoading) {
            return;
        }

        this.setState({ checkoutLoading: true });
        this.api.posCreateSalesOrder({
            orderType: orderType,
            ...req
        }, response => {
            if (response.status === true) {
                this.setState({ checkoutLoading: false, showCheckoutDialog: false, showTakeOutCheckoutDialog: false, showDineInCheckoutDialog: false, showDeliveryCheckoutDialog: false, sessionUpdateId: Util.newTempId() });
                this.onUpdateSession(response.payload)
                UIUtil.showSuccess();
                if (successListener) {
                    successListener();
                }

                if (hasCapabilitySupport("restaurant")) {
                    this.setState({ tab: "orders" })
                }

                if (hasCapabilitySupport("extendedPosCheckout") && getAccountRole() === ACCOUNT_TYPE_ADMINISTRATION) {
                    renderNode(onClose => (
                        <SidePanel md2 backBtn onClose={onClose}>
                            <LockScroll />
                            <DeliverableItemPage orderIdOverride={response.payload._transient_param_salesOrder.id} mainApp={this.mainApp} />
                        </SidePanel>
                    ))
                }
            } else {
                this.setState({ checkoutLoading: false });
                UIUtil.showError(response.message);
                if (errorListener) {
                    errorListener();
                }
            }
        })
    }

    printSalesSummary(onDone, opts = {}) {
        if (this.mainApp) {
            UIUtil.inputPrompt({
                title: "Clearance code",
                message: "Input POS operator or admin clearance code",
                initialValue: "",
            }).then(clearanceCode => {
                if (!clearanceCode) {
                    UIUtil.showInfo("Cancelled");
                    onDone?.()
                    return;
                }

                Api.checkPosSessionClearanceCode(clearanceCode, response => {
                    if (response.status === true) {
                        this.mainApp.thermalPrinter.printSalesSummary({ ...this.getSession() }, onDone, opts)
                    } else {
                        UIUtil.showError(response.message)
                        onDone?.()
                        return;
                    }
                })
            });
        } else {
            UIUtil.showInfo("Not supported in this context")
            onDone?.()
        }
    }

    setShowCheckoutDialog(showCheckoutDialog) {
        this.setState({ showCheckoutDialog })
    }

    setShowTakeOutCheckoutDialog(showTakeOutCheckoutDialog) {
        this.setState({ showCheckoutDialog: showTakeOutCheckoutDialog, showTakeOutCheckoutDialog, showDineInCheckoutDialog: false })
    }

    setShowDineInCheckoutDialog(showDineInCheckoutDialog) {
        this.setState({ showCheckoutDialog: showDineInCheckoutDialog, showDineInCheckoutDialog, showTakeOutCheckoutDialog: false })
    }

    setShowDeliveryCheckoutDialog(showDeliveryCheckoutDialog) {
        //this.setState({ showCheckoutDialog: showDeliveryCheckoutDialog, showDeliveryCheckoutDialog })
        this.setState({ showDeliveryCheckoutDialog })
    }

    isShowCheckoutDialog() {
        return this.getState().showCheckoutDialog;
    }

    isShowTakeOutCheckoutDialog() {
        return this.getState().showTakeOutCheckoutDialog;
    }

    isShowDineInCheckoutDialog() {
        return this.getState().showDineInCheckoutDialog
    }

    isShowDeliveryCheckoutDialog() {
        return this.getState().showDeliveryCheckoutDialog;
    }

    setCheckoutLoading(checkoutLoading) {
        this.setState({ checkoutLoading })
    }

    isCheckoutLoading() {
        // return true;
        return this.getState().checkoutLoading
    }

    setHighlightProductRequestBarcode(highlightProductRequestBarcode) {
        this.setState({ highlightProductRequestBarcode });
    }

    getHighlightProductRequestBarcode() {
        return this.getState().highlightProductRequestBarcode;
    }

    isShowHighlightProductDialog() {
        return this.getState().showHighlightProductDialog;
    }

    setShowHighlightProductDialog(showHighlightProductDialog, highlightProductRequestBarcode) {
        if (showHighlightProductDialog) {
            this.setState({ showHighlightProductDialog, highlightProductBarcodeValue: "", highlightProductRequestBarcode: "" })
        } else {
            this.setState({ showHighlightProductDialog, highlightProductRequestBarcode })
        }
    }

    getHighlightProductBarcodeValue() {
        return this.getState().highlightProductBarcodeValue;
    }

    setHighlightProductBarcodeValue(highlightProductBarcodeValue) {
        this.setState({ highlightProductBarcodeValue })
    }

    setCustomerId(customerId, listener) {
        this.api.setPosCustomer(customerId, response => {
            if (response.status === true) {
                this.onUpdateSession(response.payload)
                listener(true)
            } else {
                listener(false)
            }
        })
    }

    createRestCart(listener) {
        this.api.createRestCart(response => {
            if (response.status === true) {
                this.onUpdateSession(response.payload)
                listener(true);
            } else {
                listener(false);
            }
        })
    }

    setCartDataWaiterCalled(value, listener) {
        this.api.setCartDataWaiterCalled(value, response => {
            if (response.status === true) {
                this.onUpdateSession(response.payload)
                listener(true)
            } else {
                listener(false)
            }
        })
    }

    setCartDataOrderSubmitted(value, listener) {
        this.api.setCartDataOrderSubmitted(value, response => {
            if (response.status === true) {
                this.onUpdateSession(response.payload)
                listener(true)
            } else {
                listener(false)
            }
        })
    }

    setCartDataModifyOrderSubmitted(value, listener) {
        this.api.setCartDataModifyOrderSubmitted(value, response => {
            if (response.status === true) {
                this.onUpdateSession(response.payload)
                listener(true)
            } else {
                listener(false)
            }
        })
    }

    clearCart(listener) {
        this.api.clearCart(response => {
            if (response.status === true) {
                this.onUpdateSession(response.payload)
                listener(true)
            } else {
                listener(false)
            }
        })
    }

    holdCart(listener) {
        this.api.newCart(response => {
            if (response.status === true) {
                this.onUpdateSession(response.payload)
                listener(true)
            } else {
                listener(false)
            }
        })
    }

    removeCartFromHold(cartToRemove) {
        this.setState(prevState => {
            const heldCarts = prevState.heldCarts.filter(cart => cart !== cartToRemove);

            return {
                heldCarts, posSession: {
                    ...prevState.posSession,
                    cartIds: heldCarts.map(cart => cart.id)
                }
            }
        });
    }

    getHeldCarts() {
        return this.getState().heldCarts;
    }

    setShowHeldCartsDialog(showHeldCartsDialog, heldCarts) {
        if (showHeldCartsDialog) {
            this.setState({ showHeldCartsDialog: true, heldCarts })
        } else {
            this.setState({ showHeldCartsDialog: false })
        }
    }

    isShowHeldCartsDialog() {
        return this.getState().showHeldCartsDialog;
    }

    setShowQuantitySetDialog(item) {
        this.setState({ showQuantitySetDialog: item, quantitySetValue: item !== undefined ? item.quantityValue : 0 })
    }

    getShowQuantitySetItem() {
        return this.getState().showQuantitySetDialog;
    }

    isShowQuantitySetDialog() {
        return this.getState().showQuantitySetDialog !== undefined;
    }

    setQuantitySetValue(quantitySetValue) {
        this.setState({ quantitySetValue })
    }

    getQuantitySetValue() {
        return this.getState().quantitySetValue;
    }

    setSettingQuantityLoading(settingQuantityLoading) {
        this.setState({ settingQuantityLoading })
    }

    isSettingQuantityLoading() {
        return this.getState().settingQuantityLoading;
    }

    hasLoyaltyCard() {
        return this.getSession().customerLoyaltyPoints !== null;
    }



    isLoadingCartUpdate() {
        return this.getState().loadingCartUpdate;
    }

    scanBarcode(barcode, silent, onDone) {
        if (!Util.isStringExists(barcode)) {
            return;
        }

        this.setState({ loadingCartUpdate: true })
        this.api.scanPosBarcode(barcode, response => {
            this.setState({ loadingCartUpdate: false })
            if (response.status === true) {
                switch (response.payload.scanType) {
                    case POS_BARCODE_SCAN_TYPE_PRODUCT: case '0':
                        //this.onItemUpdated({ status: true, payload: response.payload.result.payload }, false, silent);
                        this.onUpdateSession(response.payload.result.payload);
                        UIUtil.showSuccess("Itme added")
                        break;
                    case POS_BARCODE_SCAN_TYPE_CUSTOMER:
                        this.onUpdateSession(response.payload.result.payload);
                        UIUtil.showSuccess("Customer set!");
                        break;
                    case POS_BARCODE_SCAN_TYPE_COUPON:
                        this.onUpdateSession(response.payload.result.payload);
                        UIUtil.showSuccess("Coupon applied!");
                        break;
                }
            } else {
                if (response.message == "Barcode not found") {
                    UIUtil.showMsg("Barcode not found!")
                    try {
                        this.notificationAudio.pause();
                        this.notificationAudio.currentTime = 0;
                        this.notificationAudio.play().catch(() => { })
                    } catch (e) { }
                } else {
                    if (!silent) {
                        UIUtil.showError(response.message);
                    }
                }
            }

            if (typeof onDone === 'function') {
                onDone();
            }
        })
    }

    updateCartItemRecipeOptions(cartItemId, recipeOptions, onDone) {
        this.setState({ loadingCartUpdate: true })
        const req = { cartItemId, recipeOptions };
        const listener = response => {
            this.setState({ loadingCartUpdate: false })
            if (response.status === true) {
                this.onUpdateSession(response.payload);
                onDone(true);
            } else {
                UIUtil.showError(response.message);
                onDone(false);
            }
        };
        if (this.salesOrderSession) {
            this.api.updateCartItemRecipeOptionsForSalesOrder(this.salesOrderId, req, listener)
        } else {
            this.api.updateCartItemRecipeOptions(getTerminal().id, req, listener)
        }
    }

    setCartItemGender(cartItemId, gender, onDone) {
        const listener = response => {
            if (response.status === true) {
                this.onUpdateSession(response.payload);
                onDone?.(true);
            } else {
                UIUtil.showError(response.message);
                onDone?.(false);
            }
        };

        if (this.salesOrderSession) {
            this.api.updateCartItemGenderForSalesOrder(this.salesOrderId, cartItemId, gender, listener)
        } else {
            this.api.updateCartItemGender(cartItemId, gender, listener)
        }
    }

    updateCartItem(itemId, quantity, removing, silent, skipLoading, onDone, updateReq = {}) {
        if (!skipLoading) {
            this.setState({ loadingCartUpdate: true })
        }
        const request = { itemId, quantity, removing, ...updateReq };
        const listener = response => {
            if (response.status === false && !response.payload) {
                UIUtil.showError(response.message);
                return;
            }

            if (this.salesOrderSession || true) {
                this.setState({ loadingCartUpdate: false })
                this.onUpdateSession(response.payload);
                if (onDone !== undefined) {
                    onDone();
                }
            } else {
                this.onItemUpdated(response, removing, silent, skipLoading, onDone);
            }
        }

        if (this.salesOrderSession) {
            this.api.updateSalesOrderCartItem(this.salesOrderId, request, listener)
        } else {
            //this.api.updateCartItem(request, listener)
            this.api.updateCartItem({ ...request, loadInfo: true }, listener)
        }
    }

    updateContinuousCartItem(itemId, quantity, displayUom, updateReq = {}) {
        this.setState({ loadingCartUpdate: true })
        const request = { itemId, quantity, continuous: true, displayUom, ...updateReq };
        const listener = response => {
            if (response.status === false && !response.payload) {
                UIUtil.showError(response.message);
                return;
            }

            if (this.salesOrderSession || true) {
                this.onUpdateSession(response.payload);
            } else {
                this.onItemUpdated(response, response.status === true ? response.payload.lessThanPreviousQuantity : false);
            }
        }

        if (this.salesOrderSession) {
            this.api.updateSalesOrderCartItem(this.salesOrderId, request, listener)
        } else {
            //this.api.updateCartItem(request, listener)
            this.api.updateCartItem({ ...request, loadInfo: true }, listener)
        }
    }

    onItemUpdated(response, removing, silent, skipLoading, onDone) {
        if (onDone !== undefined) {
            onDone();
        }
        if (!skipLoading) {
            this.setState({ loadingCartUpdate: false })
        }
        if (response.status === true) {
            this.setState(prevState => {
                let currentCartItems = prevState.posSession.currentCartItems;
                const itemId = response.payload.cartItem.itemId;

                let added = false;

                for (let i = 0; i < currentCartItems.length; i++) {
                    const item = currentCartItems[i];
                    if (item.itemId == itemId) {
                        if (response.payload.removing) {
                            if (response.payload.cartItem.quantityValue <= 0) {
                                currentCartItems.splice(i, 1);
                            } else {
                                currentCartItems[i] = response.payload.cartItem;
                            }
                        } else {
                            //item.quantityValue = response.payload.cartItem.quantityValue;
                            currentCartItems[i] = response.payload.cartItem;
                            added = true;
                        }
                        break;
                    }
                }

                if (!response.payload.removing && !added) {
                    currentCartItems.push(response.payload.cartItem);
                }

                return {
                    posSession: {
                        ...prevState.posSession,
                        currentCartItems: [
                            ...currentCartItems
                        ],
                        posTotals: response.payload.totals
                    }
                }
            })
            UIUtil.showSuccess(removing ? "Item removed!" : "Item added!");
        } else {
            if (!silent) {
                UIUtil.showError(response.message);
            }
        }
    }

    getSubtotal() {
        const posTotals = this.getSession().posTotals;
        // return posTotals.currency + " " + (posTotals.subtotal !== null ? posTotals.subtotal : 0).toFixed(2);
        return (posTotals.subtotal !== null ? posTotals.subtotal : 0).toFixed(2);
    }

    getRawSubtotal() {
        const posTotals = this.getSession().posTotals;
        return posTotals.subtotal !== null ? posTotals.subtotal : 0;
    }

    getDiscount() {
        // return this.getSession().posTotals.currency + " " + this.getRawDiscount().toFixed(2);
        return this.getRawDiscount().toFixed(2);
    }

    getTotalItems() {
        return this.getSession()?.totalItems ?? 0
    }

    getTotalQty() {
        return this.getSession()?.totalQty?.toFixed?.(2) ?? 0;
    }

    getRawDiscount() {
        const posTotals = this.getSession().posTotals;
        return posTotals.discount !== null ? posTotals.discount : 0;
    }

    getTax() {
        const posTotals = this.getSession().posTotals;
        // return posTotals.currency + " " + (posTotals.tax !== null ? posTotals.tax : 0).toFixed(2);
        return (posTotals.tax !== null ? posTotals.tax : 0).toFixed(2);
    }

    getTotal() {
        const posTotals = this.getSession().posTotals;
        let value = (posTotals.total !== null ? posTotals.total : 0).toFixed(2);
        if (Number.isInteger(parseFloat(value))) {
            value = parseFloat(value).toFixed(0);
        }
        return posTotals.currency + " " + value;
        // return (posTotals.total !== null ? posTotals.total : 0).toFixed(2);
    }

    getRawTotal() {
        const posTotals = this.getSession().posTotals;
        return posTotals.total !== null ? posTotals.total : 0;
    }

    getTotalSavings() {
        const posTotals = this.getSession().posTotals;
        return (posTotals.totalSavings !== null ? posTotals.totalSavings : 0).toFixed(2);
    }

    getCartItems() {
        //return this.getState().posSession.currentCartItems;
        return this.getState().posSession.currentCartItems?.filter(cartItem => !Util.isNumberExist(cartItem.cinemaTicketShowtimeId) && !cartItem.flagDeliveryFee);
    }

    getAllCartItems() {
        return this.getState().posSession.currentCartItems;
    }

    getTicketCartItem() {
        return this.getState().posSession.currentCartItems?.find(cartItem => Util.isNumberExist(cartItem.cinemaTicketShowtimeId));
    }

    getSession() {
        return this.getState().posSession;
    }

    onUpdateSession(posSession) {
        this.setState({ posSession, })
    }

    onUpdateSessionAndExtraProp(posSession, prop) {
        this.setState({ posSession, ...prop })
    }

    onUpdateSessionAfterTicketAdded(posSession) {
        this.setState({ posSession, showTicketSelector: false })
    }

    loadExternalSession(posSession) {
        this.setState({ ...getDefaultPosState(), loadingPos: false, posSession })
    }

    loadSession(subscribeInput, loadListener) {
        this.setState({ ...getDefaultPosState(), loadingPos: true })

        if (hasTerminal() || this.isCartSession) {
            if (subscribeInput) {
                this.updateId = Util.newTempId();
                this.onMonitoringInputEventListener = (event => {
                    if (event.setUpdateValue && event.updateId != this.updateId) {
                        switch (event.updateProperty) {
                            case "fullName":
                                this.setState({ valueNewCustomerUpdateFullName: event.updateValue })
                                break;
                            case "email":
                                this.setState({ valueNewCustomerUpdateEmailAddress: event.updateValue })
                                break;
                            case "phoneNumber":
                                this.setState({ valueNewCustomerUpdatePhoneNumber: event.updateValue })
                                break;
                            case "searchCustomerValue":
                                this.setState({ searchCustomerValue: event.updateValue })
                                break;
                        }
                    }

                    if (event.updateSession && event.updateId != this.updateId) {
                        this.onUpdateSession(event.updateSessionInfo)
                    }

                    if (event.newCartId && !CART_ID_CACHE.includes(event.newCartId)) {
                        CART_ID_CACHE.push(event.newCartId)
                        this.setState(prevState => ({
                            posSession: {
                                ...prevState.posSession,
                                cartIds: [...prevState.posSession.cartIds, event.newCartId]
                            }
                        }))
                        toast(event.newCartSenderName + ' sent you a cart and is now in your held carts', {
                            position: "top-right",
                            autoClose: 3000,
                            theme: "dark",
                            type: "success",
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                }).bind(this);
                SocketSession.registerListener(SOCKET_EVENT_POS_MONITORING_INPUT + "-" + getTerminal().id, this.onMonitoringInputEventListener);
                SocketSession.subscribe(SOCKET_EVENT_POS_MONITORING_INPUT + "-" + getTerminal().id, subscription => {
                    this.socketSubscription = subscription;
                    this.api.getPosSession(response => {
                        if (response.status === true) {
                            this.setState({ loadingPos: false, posSession: response.payload }, () => this.loadSessionEventListener())
                        } else {
                            this.setState({ loadingPos: false, inError: true })
                        }
                    })
                })



            } else {
                this.cancelSubscriptions();

                this.api.getPosSession(response => {
                    if (response.status === true) {
                        this.setState({ loadingPos: false, posSession: response.payload }, () => {
                            this.loadSessionEventListener();
                            if (loadListener) {
                                loadListener?.(true)
                            }
                        })
                    } else {
                        this.setState({ loadingPos: false, inError: true }, () => {
                            if (loadListener) {
                                loadListener?.(false)
                            }
                        })
                    }
                })
            }



        } else {
            this.setState({ loadingPos: false, hasNoTerminalError: true })
        }
    }

    reloadSession() {
        this.api.getPosSession(response => {
            if (response.status === true) {
                this.setState({ posSession: response.payload })
            }
        })
    }

    reloadSessionOnce() {
        if (this.__reloading_session_once) {
            return;
        }

        this.__reloading_session_once = true;
        this.api.getPosSession(response => {
            this.__reloading_session_once = false
            if (response.status === true) {
                this.setState({ posSession: response.payload })
            }
        })
    }


    loadSalesOrderSession(orderId) {
        this.salesOrderId = orderId;
        this.salesOrderSession = true;

        this.setState({ ...getDefaultPosState(), loadingPos: true })
        this.cancelSubscriptions();
        this.api.getSalesOrderPosSession(orderId, response => {
            if (response.status === true) {
                this.setState({ loadingPos: false, posSession: response.payload }, () => this.loadSessionEventListener())
            } else {
                this.setState({ loadingPos: false, inError: true })
            }
        })
    }

    loadSessionEventListener() {
        if (this.getState().posSession === null || this.getState().posSession === undefined) {
            return;
        }

        this.socketSessionEvent = SOCKET_EVENT_POS_SESSION + "-" + this.getState().posSession.posSessionId;
        this.sessionEventListener = event => {
            if (event.type === SESSION_EVENT_TYPE_SESSION_END) {
                this.forceStopSession();
            }
        }
        SocketSession.registerListener(this.socketSessionEvent, this.sessionEventListener)
        SocketSession.subscribe(this.socketSessionEvent, subscription => {
            this.sessionSocketSubscription = subscription;
        })
    }

    cancelSubscriptions() {
        if (this.sessionEventListener) {
            SocketSession.unregisterListener(this.socketSessionEvent, this.sessionEventListener)
        }
        if (this.sessionSocketSubscription !== undefined) {
            this.sessionSocketSubscription.unsubscribe();
            this.sessionSocketSubscription = undefined;
        }


        if (this.onMonitoringInputEventListener) {
            SocketSession.unregisterListener(SOCKET_EVENT_POS_MONITORING_INPUT + "-" + getTerminal().id, this.onMonitoringInputEventListener);
        }
        if (this.socketSubscription !== undefined) {
            this.socketSubscription.unsubscribe();
            this.socketSubscription = undefined;
        }
    }

    startSession(startingAmount) {
        this.setState({ loadingPosAction: true })
        this.api.startPos(startingAmount, response => {
            if (response.status === true) {
                this.setState({ loadingPosAction: false, posSession: response.payload }, () => this.loadSessionEventListener())
                UIUtil.showSuccess();
            } else {
                this.setState({ loadingPosAction: false })
                UIUtil.showError(response.message);
            }
        })
    }

    forceStopSession() {
        UIUtil.showInfo("Your session has ended!");
        this.loadSession();
    }

    stopSession() {
        const endingAmount = this.getEndPosAmount();
        const logoutOnEnd = this.getSession()?.logoutOnEndSession === true;

        if (isNaN(endingAmount) || endingAmount === "") {
            UIUtil.showError("Invalid number inputted")
            return;
        }

        this.setState({ loadingPosAction: true, showEndPosDialog: false })
        this.api.stopPos(endingAmount, response => {
            this.setState({ loadingPosAction: false })
            if (response.status === true) {
                UIUtil.showSuccess();

                if (logoutOnEnd) {
                    this.mainApp.logout()
                } else {
                    this.loadSession();
                }
            } else {
                UIUtil.showError();
            }
        })
    }

    showResumeFromBreakDialog() {
        this.setState({ showResumeFromBreakPos: true, resumePosClearanceCode: "" })
    }

    closeResumeFromBreakDialog() {
        this.setState({ showResumeFromBreakPos: false })
    }

    confirmToGoOnBreak() {
        this.setState({ showGoOnBreakConfirm: true })
    }

    declineConfirmToGoOnBreak() {
        this.setState({ showGoOnBreakConfirm: false })
    }

    getResumePosClearanceCode() {
        return this.getState().resumePosClearanceCode;
    }

    setResumePosClearanceCode(code) {
        this.setState({ resumePosClearanceCode: code });
    }

    setOnBreak(onBreak) {
        this.setState({ showGoOnBreakConfirm: false, showResumeFromBreakPos: false, loadingPosSetOnBreak: true })
        this.api.setPosOnBreak(!onBreak ? this.getState().resumePosClearanceCode : undefined, onBreak, response => {
            this.setState({ loadingPosSetOnBreak: false, showPosInfoDialog: false })
            if (response.status === true) {
                if (!onBreak) {
                    UIUtil.showSuccess("Welcome back! Your break lasted for " + Util.formatSecondsPretty((new Date().getTime() - response.payload) / 1000) + "!", 4000);
                }
                this.setState(prevState => ({
                    posSession: {
                        ...prevState.posSession, onBreak
                    }
                }))
            } else {
                UIUtil.showError(response.message);
            }
        })
    }

    onCustomerUpdate(customer) {
        this.setState(prevState => ({
            posSession: {
                ...prevState.posSession, ...(customer !== undefined ? customer : { customerId: 0, customerName: null })
            }
        }))
    }

    onCreatingCustomer() {
        this.setState({ creatingCustomerLoading: true, showCustomerCreatorDialog: false })
    }

    setCreatingCustomerLoading(creatingCustomerLoading) {
        this.setState({ creatingCustomerLoading });
    }

    isCreatingCustomerLoading() {
        return this.getState().creatingCustomerLoading;
    }

    setShowCustomerCreatorDialog(showCustomerCreatorDialog) {
        this.setState({
            showCustomerCreatorDialog,
            valueNewCustomerUpdateFullName: "",
            valueNewCustomerUpdateEmailAddress: "",
            valueNewCustomerUpdatePhoneNumber: ""
        });
        this.api.openNewCustomerDialog(getTerminal().id, showCustomerCreatorDialog, () => { });
    }

    isShowCustomerCreatorDialog() {
        return this.getState().showCustomerCreatorDialog;
    }

    showStopSessionDialog() {
        this.setState({ showPosInfoDialog: false, showEndPosDialog: true, endPosAmount: 0 })
    }

    setEndPosAmount(endPosAmount) {
        this.setState({ endPosAmount });
    }

    getEndPosAmount() {
        return this.getState().endPosAmount;
    }

    setShowEndPosDialog(showEndPosDialog) {
        this.setState({ showEndPosDialog })
    }

    isShowEndPosDialog() {
        return this.getState().showEndPosDialog;
    }

    setShowPosInfoDialog(showPosInfoDialog) {
        this.setState({ showPosInfoDialog })
    }

    isShowPosInfoDialog() {
        return this.getState().showPosInfoDialog;
    }

    isShowResumeFromBreakDialog() {
        return this.getState().showResumeFromBreakPos;
    }

    isShowGoOnBreakConfirm() {
        return this.getState().showGoOnBreakConfirm;
    }

    isOnBreak() {
        return this.hasPos() && this.getState().posSession.onBreak;
    }

    hasPos() {
        return this.getState().posSession !== null;
    }

    isLoadingPos() {
        return this.getState().loadingPos;
    }

    isLoadingPosAction() {
        return this.getState().loadingPosAction;
    }

    isLoadingPosSetOnBreak() {
        return this.getState().loadingPosSetOnBreak;
    }

    isHasNoTerminalError() {
        return this.getState().hasNoTerminalError;
    }

    isInError() {
        return this.getState().inError;
    }





    isShowTicketSelector() {
        return this.getState().showTicketSelector;
    }

    getTicketSelectorDefaultValues() {
        return this.getState().ticketSelectorDefaultValues;
    }

    openTicketSelector(defaultValues) {
        this.setState({ showTicketSelector: true, ticketSelectorDefaultValues: defaultValues })
    }

    closeTicketSelector() {
        this.setState({ showTicketSelector: false, ticketSelectorDefaultValues: undefined })
    }


    useRestOrderType() {
        return [this.getSession()?.restOrderType, restOrderType => this.setOrderRestType(restOrderType)];
    }

    isDineInMode() {
        return this.getSession()?.restOrderType === "dine-in"
    }

    shouldShowTableSelect() {
        return this.getSession() && this.getTableId() <= 0 && this.getSession().restOrderType === "dine-in"
    }


    setHidePrice(hidePrice) {
        this.setState({ hidePrice })
    }

    get hidePrice() {
        return this.getState().hidePrice;
    }


    async onCartItemFocButton(cartItem) {
        let clearanceCode;
        if (!this.monitoringSession || getAccountRole() !== ACCOUNT_TYPE_ADMINISTRATION) {
            clearanceCode = await UIUtil.promptClearanceCode('Enter clearance code to proceed');
            if (!clearanceCode) {
                return;
            }
        }

        // this.getState().freeFormDiscountAmountValue, this.getState().freeFormDiscountClearanceCodeValue, this.getState().freeFormDiscountPercMode, this.getState().freeFormDiscountCartItemOnly?.id        
        this.setState({
            freeFormDiscountAmountValue: this.hasFocApplied(cartItem) ? 0 : 100,
            freeFormDiscountClearanceCodeValue: clearanceCode,
            freeFormDiscountPercMode: 1, // percentage
            freeFormDiscountCartItemOnly: cartItem,
        }, () => this.addFreeFormDiscount());
    }

    hasFocApplied(cartItem) {
        console.log(cartItem.totalAmount)
        console.log(cartItem.itemDiscountedTotal)

        return cartItem.totalAmount.price > 0 && cartItem.itemDiscountedTotal === 0;
    }
}

export default PosState;