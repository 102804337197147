import { ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_SUPERVISOR } from "../../../constants/Constants"
import { getAccountRole } from "../../../session/SessionManager"
import { isV2 } from "../../../util/Util";
import { DateRange, EndDateOnly } from "../../components/basic-filter/date-range";
import { openAccountLedger } from "../balance-sheet0";
import { AmountTagSelector } from "../../components/basic-filter/amount-tag-selector"
import { CheckboxFilter } from "../../components/basic-filter/check-box-filter";
import { openAccountLedger2 } from "../balance-sheet";

export const TrialBalance = {
    filter: Filter,
    viewBased: true,
    cellOpt: {
        frozenWidth: 400,
        rightAligned: ['formattedDebit', 'formattedCredit'],
        onClick: (history, data) => openAccountLedger2(data)
    },

    customPath: !isV2() ? "/trial-balance" : undefined,

    onAction: (action, data, history) => {
        switch (action) {
            case "debit": case "credit":
                let accountId = data.id;
                if (typeof accountId === "string") {
                    accountId = accountId.replace("-other", '');
                    switch (accountId) {
                        case "asset":
                            accountId = -1;
                            break;
                        case "liabilities":
                            accountId = -2;
                            break;
                        case "equity":
                            accountId = -3;
                            break;
                        case "income":
                            accountId = -4;
                            break;
                        case "expense":
                            accountId = -5;
                            break;
                    }
                }

                openAccountLedger("TrialBalance", history, accountId, data.name?.replace(" Total", "").replace(" - Other", ""), 0, data._asOfDate)
                return;
        }
    },

    isAvailable: () => {
        const role = getAccountRole();
        return role == ACCOUNT_TYPE_ADMINISTRATION || role == ACCOUNT_TYPE_SUPERVISOR;
    }
}

const SHOW_ZERO_BALANCE_FILTER = {
    skipAutoFilter: true,
    value: true
}

function Filter({ endpoint, state }) {
    return (<>
        <EndDateOnly state={state} property="date" />
        <CheckboxFilter state={state} filterId="showZeroBalance" label="Show 0 balance" filter={SHOW_ZERO_BALANCE_FILTER} />

        <div style={{ height: 30, marginTop: '0rem', flexBasis: '100%' }}>
            <AmountTagSelector options={endpoint.tags} state={state} property="tags" />
        </div>
    </>)
}
