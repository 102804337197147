import { useEffect, useMemo, useState } from "react";
import { ComboBoxField } from "./ComboBoxField";
import Api from "../session/Api";
import UIUtil from "../util/UIUtil";



export function ApiComboBoxField({ apiFn, ...props }) {
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        let cancelled = false;

        setLoading(true);
        setError(null);
        setOptions([]);

        apiFn.bind(Api)(response => {
            if (cancelled) {
                return;
            }

            try {
                if (response.status === true) {
                    setOptions(response.payload);
                } else {
                    setError(response.message || "Unknown error");
                }
            } finally {
                setLoading(false);
            }
        })


        return () => {
            cancelled = true;
        }
    }, []);

    const onClick = () => {
        if (loading) {
            UIUtil.showInfo("Loading Options...");
        }

        if (error) {
            UIUtil.showError(error);
        }
    }

    return (
        <div style={{ display: 'contents' }} onMouseUp={onClick}>
            <ComboBoxField
                {...props}
                disabled={loading}
                options={options}
            />
        </div>
    )
}