import { Wallet16, Checkmark16 } from '@carbon/icons-react'
import { Tag } from 'carbon-components-react'

function StatusBadge({ state }) {
    const props = (() => {
        switch (state.status) {
            case 'succeeded':
                return { type: 'green', icon: Checkmark16, label: 'Succeeded' }
            default:
                return { label: state.statusStr };
        }
    })();

    if (!props) {
        return null;
    }

    return <Tag type={props.type} renderIcon={props.icon}>{props.label}</Tag>
}

export function StripePaymentCard({ state }) {
    return (
        <div style={{ background: '#f0f0f0', padding: '1rem', margin: '2rem', marginBottom: '-1rem', display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.5rem', gap: '0.5rem' }}>
                <Wallet16 />
                <h6 style={{ flex: 1 }}>
                    Stripe Online Payment Status
                </h6>
                <a target="_blank" href={state.stripeUrl}>
                    View in Stripe Dashboard
                </a>
            </div>
            <p style={{}}>Payment ID: {state.id}</p>
            <div style={{
                background: 'white', borderRadius: '0.5rem', marginBlockStart: '0.5rem', padding: '0.5rem', display: 'flex', flexDirection: 'column', gap: '0.5rem',
                border: '1px solid #00000020'
            }}>
                <div>
                    <StatusBadge state={state} />
                </div>
            </div>
        </div>
    )
}