import { ComboBox, Tag, TextInput } from "carbon-components-react"
import { ProductListSelector, StockReqItemOpts } from "../../../components/list-selector"
import { TableInput } from "../../../components/table-input"
import { ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_SUPERVISOR } from "../../../constants/Constants"
import { getAccountRole } from "../../../session/SessionManager"
import { FormSection } from "../../../templates/form/form"
import { newListDetail } from "../../base/list-detail"
import { Cube32, Cube24, Checkmark16, ArrowRight24, Close16, Pdf16, ArrowRight16 } from '@carbon/icons-react'
import Util, { big } from "../../../util/Util"
import useStore from "../../../hooks/useStore"
import Button from "../../../components/Button"
import { useState } from "react"
import UIUtil from "../../../util/UIUtil"
import Api from "../../../session/Api"
import { InvRequestPage } from "../../../pages/inv-requests/inv-request-page"
import { DateRange, DateRange2 } from "../../components/basic-filter/date-range"
import { Divider } from "../../components/basic-filter/divider"
import { MultiSelect, MultiSelectListMode } from "../../components/basic-filter/multi-select"
import { CheckboxFilter } from "../../components/basic-filter/check-box-filter"
import { OBJECT_INV_REQUEST } from "../../../constants/ObjectTypes"
import { AdvancedNotesView } from "../../../views/advanced-notes/advanced-notes-view"
import { useMemo } from "react"
import { VAT } from "../../../app/Config"
import { openPdf } from "../../../markup-template-engine"
import { Link } from "react-router-dom"

const PRODUCT_COLS = [
    {
        key: "item", title: "Item", flex: 3, Component: ({ value, setMultiProp }) => {
            const setItem = item => {
                setMultiProp({
                    item,
                    itemId: item?.itemId ?? 0,
                    itemType: item?.itemType,
                    uomId: 0,
                    cost: item?.cost ?? '',
                })
            }
            return (
                <div style={{ height: 40, width: '100%' }}>
                    <StockReqItemOpts value={value} setValue={setItem} />
                </div>
            )
        }
    },
    {
        key: "cost", title: "Cost", flex: 1, Component: ({ value, setValue }) => {
            return <TextInput value={value} onChange={e => setValue(e.target.value)} />
        }
    },
    {
        key: "quantity", title: "Qty", flex: 1, Component: ({ value, setValue }) => {
            return <TextInput value={value} onChange={e => setValue(e.target.value)} />
        }
    },
    {
        key: "total", title: "Total", flex: 1, Component: ({ useEntry }) => {
            const [cost] = useEntry('cost');
            const [quantity] = useEntry('quantity');

            const total = useMemo(() => {
                return big(cost).times(big(quantity)).toFixed(4);
            }, [cost, quantity])

            return <TextInput value={total ?? ""} />
        }
    },
    {
        key: "uomId", title: "UOM", flex: 1, Component: ({ useEntry, value, setValue }) => {
            const [cKey, setCKey] = useState(() => Util.newTempId());
            const [item] = useEntry('item');
            const measurementType = item?.measurementType ?? {};

            const uom = measurementType.unitOfMeasurements?.find($ => $.id === value) || {
                symbol: measurementType.defaultUomSymbol || "x", name: measurementType.defaultUomName || "x"
            };

            if (!item) {
                return null;
            }

            return (
                <div style={{ width: '100%' }}>
                    <ComboBox
                        key={cKey}
                        placeholder="UOM"
                        selectedItem={uom}
                        onChange={item => {
                            setValue(item?.selectedItem?.id ?? 0)
                            setCKey(Util.newTempId())
                        }}
                        itemToString={item => item ? item.symbol : ""}
                        items={measurementType.unitOfMeasurements ?? []}
                    />
                </div>
            )
        }
    },
]


export const V1PoList = newListDetail({
    customPath: "/purchase-orders",
    title: "Purchase Order",
    icon: Cube32,
    mdIcon: Cube24,

    filter: Filter, newForm: Form, updateForm: Form,

    openDetailOnCreate: true,
    clearStateOnSave: true,
    skipInlinePadding: true,
    canDelete: false,

    defaultValues: {
        items: [],
    },

    isAvailable: () => {
        const role = getAccountRole();
        return role == ACCOUNT_TYPE_ADMINISTRATION || role == ACCOUNT_TYPE_SUPERVISOR;
    },

    renderOptions: (props) => <PendingOptions {...props} />,
})

function ItemListField({ value, setValue }) {
    return <TableInput serial minimal columns={PRODUCT_COLS} value={value} setValue={setValue} />;
}

function FinanceBar({ store }) {
    const items = useStore(store, 'items')[0] ?? [];
    const vatMode = useStore(store, 'vatMode')[0] ?? 'HAS_VAT';

    const hasTax = vatMode === 'HAS_VAT';

    const subtotal = useMemo(() => items
        .map(item => big(item.quantity).times(big(item.cost)))
        .reduce((t, c) => t.add(c), big(0)), [items])

    const tax = useMemo(() => hasTax ? big(VAT.FRAC).times(subtotal) : big(0), [hasTax, subtotal])

    const grandTotal = useMemo(() => subtotal.plus(tax), [subtotal, tax])

    const formatted = useMemo(() => ({
        subtotal: Util.formatMoney(subtotal.toNumber()),
        tax: Util.formatMoney(tax.toNumber()),
        grandTotal: Util.formatMoney(grandTotal.toNumber()),
    }), [subtotal, tax, grandTotal])

    return <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', alignItems: 'center' }}>
        <div>
            <h6>Subtotal</h6>
            <h3>AED {formatted.subtotal}</h3>
        </div>
        <div>
            <h6>Tax</h6>
            <h3>AED {formatted.tax}</h3>
        </div>
        <div>
            <h6>Grand Total</h6>
            <h2 style={{ color: 'green' }}>AED {formatted.grandTotal}</h2>
        </div>
    </div>
}

function PendingOptions({ item, onUpdate }) {
    const [loading, setLoading] = useState(false);
    const onRevokeBtn = async () => {
        const confirmed = await UIUtil.confirmPrompt({
            message: "Are you sure you want to revoke this Purchase Order?",
        })
        if (!confirmed) {
            return;
        }

        const note = await UIUtil.inputPrompt({
            title: "Revoke Reason",
            message: `Enter the reason for revoking the request.`,
            label: "Reason",
            textArea: true,
        })
        if (!note) {
            UIUtil.showInfo("Reason is required!");
            return;
        }

        setLoading(true);
        try {
            const [success, response] = await Api.try((api, listener) => api.v1PoRevoke(item.id, note, listener));
            if (!success) {
                return;
            }

            onUpdate(response)
        } finally {
            setLoading(false);
        }
    }

    const onFinalizeBtn = async () => {
        const confirmed = await UIUtil.confirmPrompt({
            message: "Are you sure you want to finalize this Purchase Order and create a Purchase?",
        })
        if (!confirmed) {
            return;
        }

        setLoading(true);
        try {
            const [success, response] = await Api.try((api, listener) => api.v1PoFinalize(item.id, listener));
            if (!success) {
                return;
            }

            onUpdate(response)
        } finally {
            setLoading(false);
        }
    }

    if (item.status !== 'PENDING') {
        return <></>;
    }

    return (<>
        <Button onClick={onRevokeBtn} loading={loading} size="sm" style={{ borderRadius: 25, marginLeft: '0.25rem' }} renderIcon={Close16} kind="danger">
            Revoke
        </Button>
        <Button onClick={onFinalizeBtn} className="green-btn" loading={loading} size="sm" style={{ borderRadius: 25, marginLeft: '0.25rem' }} renderIcon={Checkmark16}>
            Finalize / Create Purchase
        </Button>
    </>)
}

function Form({ endpoint, data, form }) {
    const readonly = data?.status === 'FINALIZED' || data?.status === 'REVOKED';

    return (
        <div>
            {data?.id && <div style={{
                marginTop: '-2rem', marginBottom: '2rem',
                paddingInline: '3rem',

            }}>
                <div style={{ background: "#f4f4f4", border: '1px solid #0000020', padding: '1rem', gap: '0.5rem', borderRadius: 7, display: 'flex', alignItems: 'center' }}>
                    <p style={{}}>LPO No <strong>#{data.orderNo}</strong></p>
                    {data.status === 'PENDING' && <Tag type="blue">Pending</Tag>}
                    {data.status === 'FINALIZED' && <Tag type="green">Finalized</Tag>}
                    {data.status === 'REVOKED' && <Tag type="red">Revoked</Tag>}

                    <div style={{ flex: 1 }} />

                    <Button onClick={() => openPdf('PurchaseOrder', data.id)} size="sm" style={{ borderRadius: 5 }} renderIcon={Pdf16}>
                        PO Document
                    </Button>
                </div>

            </div>}

            <div style={{ paddingInline: '3rem', }}>
                {data?.status === "REVOKED" && <FormSection title={"Revoke Details"}>
                    <label className="bx--label" style={{ marginBottom: 0 }}>Revoked by {data.revokedByName} on {Util.getFullDate(data.revokedOn)}</label>
                    <p style={{ wordBreak: 'break-word', whiteSpace: 'pre-wrap' }}>
                        {data.revokeReason}
                    </p>
                </FormSection>}

                {data?.status === "FINALIZED" && <FormSection title={"Finalize Details"}>
                    <label className="bx--label" style={{}}>Finalized by {data.finalizedByName} on {Util.getFullDate(data.finalizedOn)}</label>
                    <br />
                    <Link to={`/stock-flow/${data.stockFlowId}`}>
                        <Button size="sm" style={{ borderRadius: 7 }} className="green-btn" renderIcon={ArrowRight16}>
                            Purchase #{Util.getVoucherNumber(data.stockFlowId)}
                        </Button>
                    </Link>
                </FormSection>}

                <div style={{ pointerEvents: readonly ? 'none' : undefined }} inert={readonly ? '' : undefined}>
                    <FormSection title={"Stock Flow"}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '3rem', }}>
                            <div style={{ flex: 1 }}>
                                <form.ComboBoxField fieldKey="supplierId" title="Supplier" options={endpoint.suppliers} />
                            </div>
                            <div style={{ height: 40, display: 'flex', alignItems: 'center', marginTop: '1rem' }}>
                                <ArrowRight24 />
                            </div>
                            <div style={{ flex: 1 }}>
                                <form.ComboBoxField fieldKey="destinationId" title="Store/Warehouse (optional)" options={endpoint.stores} />
                            </div>
                            <div style={{ flex: 1 }} />
                        </div>
                    </FormSection>

                    <FormSection title={"Purchase Info (optional)"}>
                        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: '1rem' }}>
                            <form.TextField fieldKey="supplierInvoiceNo" title="Supplier Invoice No" />
                            <form.DateField fieldKey="supplierInvoiceDate" title="Supplier Invoice Date" />
                        </div>
                    </FormSection>
                </div>
            </div>

            <div style={{ pointerEvents: readonly ? 'none' : undefined }} inert={readonly ? '' : undefined}>
                <FormSection title={"Items"} titleStyle={{ paddingInline: '3rem' }}>
                    <form.CustomField fieldKey="items">
                        {ItemListField}
                    </form.CustomField>
                </FormSection>

                <div style={{ paddingInline: '3rem', }}>
                    <FormSection title={"Finances"} titleStyle={{}}>
                        <div style={{ display: 'flex', gap: '3rem', alignItems: 'center' }}>
                            <div style={{ width: 400 }}>
                                <form.SwitchField fieldKey="vatMode" title="VAT Mode" options={[{ id: "HAS_VAT", value: "Has VAT" }, { id: "NO_VAT", value: "No VAT" }]} />
                            </div>
                            <div style={{ flex: 1 }}>
                                <form.ExtField>
                                    {FinanceBar}
                                </form.ExtField>
                            </div>
                        </div>
                    </FormSection>
                </div>

                <FormSection title={"Printed Memo / Agreements"} titleStyle={{ paddingInline: '3rem' }}>
                    <form.TextAreaField fieldKey="memo" />
                </FormSection>
            </div>

            {data?.id && <>
                <FormSection title={"Notes/Docs"} titleStyle={{ paddingInline: '3rem' }}>
                    <div style={{ marginTop: '0rem', overflow: 'hidden', width: '100%', height: 400, background: '#fafafa' }}>
                        <AdvancedNotesView objectId={data.id} objectType={OBJECT_INV_REQUEST} />
                    </div>
                </FormSection>
            </>}
        </div>
    )
}


const STATUSES = [
    'Pending',
    'Revoked',
    'Finalized',
]

function Filter({ endpoint, state }) {
    return (<>
        <DateRange2 state={state} property="createdOn" />

        <div className="list-mode-divider-quick-filter-bar" />

        <div style={{ flex: 1 }}>
            <MultiSelectListMode isMulti={false} placeholder="Status" fieldKey="invreqposliststatus" options={STATUSES} state={state} property="status" />
        </div>

        {/* <div className="list-mode-divider-quick-filter-bar" /> */}

        <div style={{ flex: 1 }}>
            <MultiSelectListMode isMulti={false} placeholder="Supplier" fieldKey="invreqposliststatussuppleir" options={endpoint.suppliers.map(s => s.value)} state={state} property="supplierId" />
        </div>
        <div style={{ flex: 1 }}>
            <MultiSelectListMode isMulti={false} placeholder="Store/Warehouse" fieldKey="invreqposliststatusstore" options={endpoint.stores.map(s => s.value)} state={state} property="storeId" />
        </div>
    </>)
}
