import { Link, Tab, Tabs, Tag, Tile } from "carbon-components-react";
import { withRouter } from "react-router-dom";
import Page from "../../base/Page";
import Api from "../../session/Api";
import { Save16, Delete16, FlowStream32, Calendar16, Receipt16, Share16, Edit16, ArrowRight16, Time16, Error16, ErrorFilled16, CheckmarkFilled16, Currency16, Information16, QrCode32, ArrowLeft16 } from '@carbon/icons-react'
import Util, { isV2 } from "../../util/Util";
import StatusIndicator from "./views/StatusIndicator";
import SalesOrderActionButtons from "./views/SalesOrderActionButtons";
import OrderSummaryView from "./views/OrderSummaryView";
import Button from "../../components/Button";

import { Link as LN } from "react-router-dom";
import NoteView from "../../views/notes/NoteView";
import { OBJECT_TYPE_SALES_ORDER } from "../../constants/ObjectTypes";
import { EditAddressButton } from "./views/edit-address-button";
import { ApplyCouponButton } from "./views/apply-coupon-button";
import { STATUS_FINALIZED, STATUS_PENDING, STATUS_REVOKED } from "./base/sales-order";
import { SetSerialNoButton } from "./views/set-serial-no-button";
import { hasCapabilitySupport } from "../../app/Capabilities";
import { UpdateOrderNo } from "./views/update-order-no-button";
import { UpdateOrderDateButton } from "./views/update-order-date-button";
import { listDetailItemId } from "../../reporting-engine/base/report-page";
import { PrintBillButton } from "./views/print-bill-button";
import { RestOrderTopOverview } from "./views/rest-order-top-overview";
import { getAccountRole } from "../../session/SessionManager";
import { ACCOUNT_TYPE_REST_DELIVERY_MANAGER } from "../../constants/Constants";
import { OrderPreInvoicePaymentIndicator } from "../pos/restaurant/components/order-card";
import { REST_ORDER_TYPE } from "../../domain/rest-sales-order";
import { ApplyFreeFormDiscount } from "./views/apply-free-form-discount-button";
import { OrderHistory } from "./tabs/history";
import { openPdf, sharePdf } from "../../markup-template-engine";
import { SetAwbNoBtn } from "./views/set-awb-no-button";
import { TypeTag } from "../pos/restaurant/components/type-tag";
import { StripePaymentCard } from "./stripe-payment-card";

const Header = ({ value, first }) => <p style={{ fontWeight: 'bold', color: 'black', marginTop: first ? 0 : '1rem' }}>{value}</p>

const FormField = ({ title, value }) => (
    <div style={{ marginBottom: '0.25rem' }}>
        <label className="bx--label" style={{ marginBottom: 0 }}>{title}</label>
        <p>{value}</p>
    </div>
)

const Details = ({ salesOrder, onUpdate }) => {
    return <OrderSummaryView salesOrder={salesOrder} summary={salesOrder.orderSummary} onUpdate={onUpdate} />
    // return (
    //     <div style={{ padding: '1rem' }}>
    //         <Header value={"Order Details"} first />
    //         <FormField title={"test"} value={"Fewf"} />
    //         <FormField title={"test"} value={"Fewf"} />
    //         <Header value={"Order Details"} />
    //         <FormField title={"test"} value={"Fewf"} />
    //     </div>
    // )
}

const Sales = ({ salesOrder }) => {
    const renderAmount = () => {
        if (Util.isNumberExist(salesOrder.amount)) {
            const amount = salesOrder.amount;
            const amountPaid = salesOrder.paidAmount;

            const amountLeft = amount - amountPaid;

            let status;
            let statusColor;
            if (amountLeft <= 0) {
                status = "Paid";
                statusColor = "green";
            } else {
                status = amountPaid == 0 ? "Not paid" : "Partially paid";
                statusColor = amountPaid == 0 ? "red" : undefined;
            }

            return (<>
                <div style={{ marginBottom: '1rem', }}>
                    <label style={{ marginBottom: '-0.25rem' }} className="bx--label">Amount</label>
                    <p>
                        AED {amount.toFixed(2)}
                    </p>
                </div>

                <div style={{ marginBottom: '1rem', }}>
                    <label style={{ marginBottom: '-0.25rem' }} className="bx--label">Amount Paid <span style={{ marginLeft: '0.25rem', color: statusColor }}>({status})</span></label>
                    <p style={{ color: amountLeft > 0 ? 'red' : 'green' }}>
                        AED {amountPaid.toFixed(2)}
                    </p>
                </div>
            </>)
        } else {
            return null;
        }
    }

    return (
        <div style={{ padding: '1rem' }}>
            <Header value={"Sales Stock Flow"} first />
            {Util.isNumberExist(salesOrder.salesStockFlowId) ? (<>
                <FormField title={"Sales Voucher No"} value={Util.getVoucherNumber(salesOrder.salesStockFlowId)} />

                <LN target="_blank" to={"/stock-flow/" + salesOrder.salesStockFlowId} style={{ textDecoration: 'none', marginTop: '1rem' }}>
                    <Button kind="secondary" renderIcon={FlowStream32} style={{ width: 195 }}>View Stock Flow</Button>
                </LN>
            </>) : (
                <p style={{ opacity: 0.65, fontSize: 12 }}>No sales occurred yet</p>
            )}

            {Util.isNumberExist(salesOrder.rentalReturnStockFlowId) && <>
                <Header value={"Rental Return Stock Flow"} />
                {Util.isNumberExist(salesOrder.rentalReturnStockFlowId) ? (<>
                    <FormField title={"Rental Return Voucher No"} value={Util.getVoucherNumber(salesOrder.rentalReturnStockFlowId)} />

                    <LN target="_blank" to={"/stock-flow/" + salesOrder.rentalReturnStockFlowId} style={{ textDecoration: 'none', marginTop: '1rem' }}>
                        <Button kind="secondary" renderIcon={FlowStream32} style={{ width: 195 }}>View Stock Flow</Button>
                    </LN>
                </>) : (
                    <p style={{ opacity: 0.65, fontSize: 12 }}>No rental return occurred yet</p>
                )}
            </>}

            <Header value={"Amount Status"} />
            {renderAmount()}
        </div>
    )
}



class DeliverableItemPage extends Page {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            salesOrder: undefined
        }
    }

    isCreating() {
        return this.getPathParams().itemId == "new";
    }

    onPageStart() {
        this.callPageApi(listener => Api.getSalesOrderForSalesOrderPage(this.props.orderIdOverride ?? this.getPathParams().itemId, listener), salesOrder => ({ salesOrder }))
    }

    getLayout() {
        const salesOrder = this.state.salesOrder;
        const role = getAccountRole()
        return (<>
            {!!salesOrder.salesOrderStripeState && <StripePaymentCard state={salesOrder.salesOrderStripeState} />}

            <div className="clear-tab-content" style={{ padding: '2rem' }}>
                {/* <Link onClick={() => this.props.history.goBack()} style={{ marginBottom: '1rem', cursor: 'pointer' }}><ArrowLeft16 style={{ marginRight: '0.25rem' }} /> Back to list</Link> */}
                {/* <Link onClick={() => this.props.history.replace("/deliverables")} style={{ marginBottom: '1rem', cursor: 'pointer' }}><ArrowLeft16 style={{ marginRight: '0.25rem' }} /> Order List</Link> */}
                <Tile style={{ padding: 0, display: 'flex' }} >
                    <div style={{ padding: '1rem', flex: 1 }}>
                        <h4 className="bx--data-table-header__title">Sales Order</h4>
                        {!hasCapabilitySupport("restaurant") && <p style={{ marginBottom: '0rem' }} className="bx--data-table-header__description">ID: {Util.getVoucherNumber(this.state.salesOrder.id)}</p>}
                        {!hasCapabilitySupport('restaurant') && <p style={{ marginBottom: '1rem' }} className="bx--data-table-header__description">Order no: {(this.state.salesOrder.orderNo)}</p>}

                        <div style={{ paddingTop: '0rem', paddingBottom: '1rem' }}>
                            {hasCapabilitySupport("restaurant") ? <>
                                {salesOrder.status !== STATUS_FINALIZED && salesOrder.status !== STATUS_REVOKED &&
                                    <div style={{ display: 'inline-block' }}>
                                        <OrderPreInvoicePaymentIndicator order={salesOrder} updateOrder={salesOrder => this.setState({ salesOrder })} />
                                    </div>}

                                <RestOrderTopOverview order={salesOrder} />

                                {/* <h6>Customer</h6>
                                {salesOrder.customerId > 0 ? (
                                    <LN to={"/customers/" + salesOrder.customerId}>
                                        <p>{salesOrder.customerName ?? "Guest"}</p>
                                    </LN>
                                ) : (
                                    <p>{salesOrder.customerName ?? "Guest"}</p>
                                )}
                                {salesOrder.shippingAddress && (<>
                                    <div style={{ height: 5 }} />
                                    <h6>Shipping Full Name</h6>
                                    <p>{salesOrder.shippingAddress.firstName} {salesOrder.shippingAddress.lastName}</p>
                                </>)} */}

                            </> : <>
                                <h6>Full Name</h6>
                                <p>{salesOrder.shippingAddress.firstName} {salesOrder.shippingAddress.lastName}</p>

                                {!isV2() && <>
                                    <div style={{ height: 5 }} />
                                    <h6 style={{ padding: '0.25rem', fontSize: 14, background: "#1c1c1c", borderRadius: 5, border: '1px solid #ffffff10', color: 'white', display: 'inline' }}>
                                        Store: {salesOrder.storeName}
                                    </h6>
                                    <div style={{ height: 10 }} />
                                    <TypeTag type={salesOrder.type} />
                                    <div style={{ height: 5 }} />

                                    {salesOrder.fromOnlineStore && <>
                                        <Tag type="blue" size='sm'>online store</Tag>
                                        <div style={{ height: 5 }} />
                                    </>}
                                </>}
                            </>}
                        </div>

                        {hasCapabilitySupport("extendedPosCheckout") && <>
                            <div style={{
                                padding: '1rem', background: 'white', boxShadow: '0px 4px 6px -1px rgba(0,0,0,0.1) , 0px 2px 4px -1px rgba(0,0,0,0.06)',
                                marginBottom: '1rem', borderRadius: 5,
                            }}>
                                {salesOrder.actualDeliveryDoneBy && <>
                                    Delivered By: <strong>{salesOrder.actualDeliveryDoneBy}</strong>
                                    <br />
                                </>}
                                AWB No: <strong>{salesOrder.awbNo || "--"}</strong>

                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: '0.5rem' }}>
                                    <SetAwbNoBtn salesOrder={salesOrder} onUpdate={salesOrder => this.setState({ salesOrder })} />
                                </div>
                            </div>
                        </>}

                        <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: '0.25rem' }}>
                            {hasCapabilitySupport("restaurant") &&
                                this.state.salesOrder?.type !== REST_ORDER_TYPE.BANQUET &&
                                this.state.salesOrder?.type !== REST_ORDER_TYPE.CATERING &&
                                <PrintBillButton mainApp={this.props.mainApp} order={this.state.salesOrder} onUpdate={order => this.setState(({ salesOrder: order }))} />}

                            {hasCapabilitySupport("extendedPosCheckout") && <>
                                <PrintBillButton mainApp={this.props.mainApp} order={this.state.salesOrder} onUpdate={order => this.setState(({ salesOrder: order }))} label="Thermal Print" />
                                {hasCapabilitySupport("templateEngine") && <>
                                    <Button onClick={() => openPdf('SalesOrder', this.state.salesOrder.id)} kind={"secondary"} size="sm" style={{ borderRadius: 50 }} renderIcon={Receipt16}>
                                        PDF Print
                                    </Button>

                                    <Button onClick={() => sharePdf("SalesOrder", this.state.salesOrder.id)} kind={"secondary"} size="sm" style={{ borderRadius: 50 }} renderIcon={Share16}>
                                        Share PDF
                                    </Button>
                                </>}
                            </>}

                            {Util.isNumberExist(this.state.salesOrder.v2SaleId) &&
                                <LN to={listDetailItemId("V2SalesList", this.state.salesOrder.v2SaleId)}><Button size="sm" style={{ borderRadius: 50 }} className="green-button" renderIcon={ArrowRight16}>View Sale</Button></LN>}

                            {this.state.salesOrder.requiresSerialNumbers && !Util.isNumberExist(salesOrder.salesStockFlowId) &&
                                <SetSerialNoButton salesOrderId={this.state.salesOrder.id} salesOrder={this.state.salesOrder} setSalesOrder={order => this.setState(prevState => ({ salesOrder: order }))} />}

                            {hasCapabilitySupport("extSales") &&
                                <UpdateOrderNo salesOrderId={this.state.salesOrder.id} onUpdate={order => this.setState(({ salesOrder: order }))} />}
                            {hasCapabilitySupport("extSales") &&
                                <UpdateOrderDateButton salesOrderId={this.state.salesOrder.id} salesOrder={this.state.salesOrder} onUpdate={order => this.setState(({ salesOrder: order }))} />}

                            {this.state.salesOrder?.type !== REST_ORDER_TYPE.BANQUET &&
                                this.state.salesOrder?.type !== REST_ORDER_TYPE.CATERING &&
                                <ApplyCouponButton salesOrderId={this.state.salesOrder.id} onUpdate={order => this.setState(({ salesOrder: order }))} />}

                            {!isV2() && this.state.salesOrder?.type !== REST_ORDER_TYPE.BANQUET &&
                                this.state.salesOrder?.type !== REST_ORDER_TYPE.CATERING &&
                                <ApplyFreeFormDiscount salesOrderId={this.state.salesOrder.id} onUpdate={order => this.setState(({ salesOrder: order }))} />}

                            {this.state.salesOrder.shippingAddress &&
                                <EditAddressButton salesOrderId={this.state.salesOrder.id} address={this.state.salesOrder.shippingAddress} setAddress={order => this.setState(prevState => ({ salesOrder: order }))} />}
                            <StatusIndicator status={this.state.salesOrder.status} state={this.state.salesOrder.orderState} />

                            <div style={{ flex: 1 }} />
                        </div>
                    </div>
                    <SalesOrderActionButtons salesOrder={this.state.salesOrder} onUpdateOrder={salesOrder => this.setState({ salesOrder })} />
                </Tile>

                <Tabs type="container" style={{ background: '#e0e0e0', display: this.isCreating() ? 'none' : undefined }}>
                    <Tab id="details" label="Details">
                        <Details salesOrder={this.state.salesOrder} onUpdate={salesOrder => this.setState({ salesOrder })} />
                    </Tab>

                    {!isV2() && <Tab id="sales" label="Sales">
                        <Sales salesOrder={this.state.salesOrder} />
                    </Tab>}
                    {hasCapabilitySupport("restaurant") && <Tab id="history" label="History">
                        <OrderHistory salesOrder={this.state.salesOrder} />
                    </Tab>}
                    {<Tab id="notes" label="Notes/Docs">
                        <div style={{ marginTop: '1rem' }}>
                            <NoteView objectType={OBJECT_TYPE_SALES_ORDER} objectId={this.state.salesOrder.id} />
                        </div>
                    </Tab>}
                </Tabs>


                <div style={{ height: '4rem' }} />
            </div>
        </>)
    }

}

export default withRouter(DeliverableItemPage);